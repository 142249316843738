import React, { useEffect, useState, Fragment, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useFormik, Formik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import Table from "../../../components/tables/table";
import { course_columns } from "../../../components/tables/tableheader";
import { courseSchema, chapterMasterSchema } from "../../../schemas";
import { Dialog, Transition } from "@headlessui/react";
import Pagination from "../../../components/Pagination";
import { FallingLinesLoader } from "../../../components/spinners/Spinner";
import Breadcrumb from "../../../components/Breadcrumb";
import ConfirmationModal from "../../../components/ConfirmationModal";
import DeleteConfirmationModal from "../../../components/DeleteConfirmationModal";
import { ArrowDownCircleIcon, ArrowUpCircleIcon, PlusCircleIcon, XMarkIcon } from "@heroicons/react/24/outline";
import MasterServices from "../../../ApiServices/MasterServices";

import { toast } from "react-toast";
import { FaSpinner } from "react-icons/fa";
import makeAnimated from 'react-select/animated';
import moment from 'moment';
import { utils, writeFile, read } from 'xlsx';
import { getAllowedActions, checkPermissionsAndRedirect, capitalizeFirstLetter } from "../../../helper/commonHelper";
import { handleDynamicFileImport } from '../../../helper/dynamicImport';
import FileUploadWithPreview from "../../../components/FileUploadWithPreview";
import chapterServices from "../../../ApiServices/chapterServices";
import Select from "react-select";
import courseServices from "../../../ApiServices/courseServices";


function CourseList() {
  const pages = [{ title: "Course List", href: "/course", module_id: 2 }];
  const animatedComponents = makeAnimated();
  const [modalOpenFlage, setmodalOpenFlage] = useState(false);
  const [course_id, setCourseId] = useState("");
  const [courseList, setCourseList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState('');
  const [selectedChapter, setSelectedChapter] = useState([]);
  const [uploadedFiles, setUploadedFiles] = useState(null);
  const [courseUploadedFiles, setCourseUploadedFiles] = useState(null);

  const [chaptersTypeList, setChaptersTypeList] = useState([
    {
      label: "TEXT",
      value: "TEXT",
    },
    {
      label: "FILE",
      value: "FILE",
    },
    {
      label: "VIDEO",
      value: "VIDEO",
    },
    {
      label: "AUDIO",
      value: "AUDIO",
    },
  ]);
  const handleChapterType = (e) => {
    setSelectedChapter(e);
  };

  const handleFileUpload = (uploadedFiles) => {
    setFieldValue("chapter_path", uploadedFiles?.fileName);
    setUploadedFiles(uploadedFiles?.fileName);
    console.log("Files received from child:", uploadedFiles);
  };

  const handleCourseFileUpload = (uploadedFiles) => {
    setFieldValue("cousre_imag_url", uploadedFiles?.fileName);
    setCourseUploadedFiles(uploadedFiles?.fileName);
    console.log("Files received from child:", uploadedFiles);
  };



  const [importLoader, setImportLoader] = useState(false);
  const fileInputRef = useRef(null);

  const [error, setError] = useState(null);

  // // Define your entity map for dynamic import (example)
  const entityCourseMap = {
    CourseName: 'CourseName',
    CourseDesc: 'CourseDesc',
  };
  const entityCourse = "Course";

  const handleCourseButtonClick = () => {
    fileInputRef.current.click();
  };

  const handleCourseImport = (event) => {
    const file = event.target.files[0];

    // File type validation
    if (file && !file.name.match(/\.(xlsx|xls)$/)) {
      setError("Please upload a valid Excel file.");
      return;
    }
    if (file) {
      setImportLoader(true);

      // Call the dynamic import utility function
      handleDynamicFileImport(file, entityCourseMap)
        .then((importData) => {
          if (importData.length > 0) {
            console.log('importData=>', importData)
            // Call API to save the imported data
            importEntities(importData);
          } else {
            setImportLoader(false);
            toast.warning('No valid data found in the file');
          }
        })
        .catch((error) => {
          setImportLoader(false);
          toast.error(`Error processing file: ${error.message}`);
        });
    } else {
      toast.error('No file selected');
    }
  };

  const importEntities = (data) => {
    const payload = { excel_data: data };
    console.log('importEntities payload=>', payload, data)

    courseServices.importCourse(payload, entityCourse)
      .then((response) => {
        setImportLoader(false);
        if (response.status === 200) {
          toast.success(`${entityCourse} imported successfully`);
          getAllCourses(3);
        } else {
          toast.error(response.data.message);
        }
      })
      .catch((error) => {
        setImportLoader(false);
        toast.error(`Error while importing ${entityCourse}`);
      });
  };

  const chapterInitialValuse = {
    chapter_id: "",
    chapter_title: "",
    chapter_type: "",
    duration: "",
    chapter_time: "",
    chapter_desc: "",
    chapter_path: "",
    fk_course_id: "",
  }

  const [chapterModal, setChapterModal] = useState(false)
  const [singleCourse, setSingleCourse] = useState({})
  const [currentCourseID, setCurrentCourseID] = useState(0)
  const [formChapter, setFormChapter] = useState(chapterInitialValuse);

  const chapterModalOpen = (id, obj) => {

    setCurrentCourseID(id);
    setChapterModal(true)
    setSingleCourse(obj)
  }

  const [disableSubmitButton, setDisableSubmitButton] = useState(false);
  const [confirmationModal, setConfirmationModal] = useState({
    status: false,
    course_id: null,
  });

  const [removeConfirmationModal, setRemoveConfirmationModal] = useState({
    status: false,
    course_id: null,
  });

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [allowedActions, setAllowedActions] = useState([]);

  // useEffect(() => {
  //   const permissions = JSON.parse(localStorage.getItem("user_permissions"));
  //   if (permissions && window.location.pathname) {
  //     const checkPermissionsAndRedirectFunc = checkPermissionsAndRedirect(window.location.pathname, permissions, navigate);
  //     const actions = getAllowedActions(permissions, window.location.pathname);
  //     setAllowedActions(actions);
  //   }
  // }, []);

  useEffect(() => {
    document.title = "EdgeFin •  Courses";
    getAllCourses(3);
  }, []);

  const getAllCourses = async (payload) => {
    setIsLoading(true);
    try {
      const res = await courseServices.getAllCourse(payload)
      if (res.status) {
        console.log("res.data.data=>", res.data.data?.courses)
        setCourseList(res?.data?.data?.courses);
        // toast.success(res?.data?.message);
      }
      setIsLoading(false);
    } catch (e) {
      console.log(e, "error in getAllModules")
      toast.error("Fatal Error")
      setIsLoading(false);
    }
  }

  const onStatusUpdate = (course_id, course_is_active) => {
    let payload = {
      table_name: "m_course",
      table_field: "course_id",
      table_field_val: course_id,
      table_status_field: "course_is_active",
      table_status_val: course_is_active ? false : true,
      table_text: "Course",
      deleted_by_key: "course_uu_by",
      delete_active_txt: course_is_active ? " Disabled" : " Enabled",
    }
    MasterServices.removeMaster(payload).then((res) => {
      const { data, status, message } = res.data
      if (status) {
        getAllCourses(3);
        toast.success(message);
      } else {
        toast.error(message)
      }
    })
  }

  let initialValues = {
    course_id: "",
    course_title: "",
    course_desc: "",
    cousre_imag_url: "",

    course_platform: "",
    course_duration: "",
    course_rating: "",
    course_reviews: "",
    course_progress: "",
    course_price: "",
    course_category: "",

  };

  const [formCourse, setFormCourse] = useState(initialValues);

  const handleDrawer = (type, id, obj) => {
    if ((type === "edit", id)) {
      setCourseId(id);
      const initialValues = {
        course_id: obj.course_id,
        course_title: obj.course_title,
        course_desc: obj.course_desc,
        cousre_imag_url: obj.cousre_imag_url || "",

        course_platform: obj.course_platform || "",
        course_duration: obj.course_duration || "",
        course_rating: obj.course_rating || "",
        course_reviews: obj.course_reviews || "",
        course_progress: obj.course_progress || "",
        course_price: obj.course_price || "",
        course_category: obj.course_category || "",
      };
      setCourseUploadedFiles(obj.cousre_imag_url);
      setFormCourse(initialValues);
    } else {
      setCourseId("");
      setFormCourse(initialValues);
    }
    if (modalOpenFlage === false) {
      setmodalOpenFlage(true);
    }
  };

  const onDeleteOpen = (course_id, course_is_active) => {
    setConfirmationModal({ course_id, course_is_active, status: true });
  };

  const onDeleteOpenSection = (course_id, course_is_deleted) => {
    setRemoveConfirmationModal({ course_id, course_is_deleted, status: true });
  };

  const { setFieldValue, values, errors, touched, handleBlur, handleChange, handleSubmit } =
    useFormik({
      enableReinitialize: true,
      initialValues: formCourse,
      validationSchema: courseSchema,
      onSubmit: async (values, action) => {

        let body = {
          course_id: course_id,
          course_title: values.course_title,
          course_desc: values.course_desc,
          cousre_imag_url: values.cousre_imag_url || courseUploadedFiles,

          course_platform: values.course_platform,
          course_duration: values.course_duration,
          course_rating: values.course_rating,
          course_reviews: values.course_reviews,
          course_progress: values.course_progress,
          course_price: values.course_price,
          course_category: values.course_category,
        };

        if (body.course_title != undefined || body.course_title != null || body.course_title != "") {
          setCourseList([]);
          setIsLoading(true);
          setTimeout(() => {
            setDisableSubmitButton(false)
          }, 1000)
          setDisableSubmitButton(true)
          let { data, status, message } = await courseServices.SaveCourse(body)
          if (status === 200) {
            if (data.status) {
              action.resetForm();
              toast.success(data.message);
              getAllCourses(3);
              setIsLoading(false)
              setmodalOpenFlage(false);
              setDisableSubmitButton(false)
            } else {
              toast.success(data.message);
              setErrorMessage(data.message);
              setTimeout(() => {
                setErrorMessage('')
              }, 1000)
              setIsLoading(false);
              setDisableSubmitButton(false);
            }
          } else {
            setDisableSubmitButton(false);
          }
        } else {
          setDisableSubmitButton(false)

          action.resetForm();
          if (modalOpenFlage === true) {
            getAllCourses(3);
            setmodalOpenFlage(false);
          }
          setDisableSubmitButton(false)
        }
      },
    });

  const onDeleteCourse = async (course_id) => {
    let { data, status } = await courseServices.deleteCourse({ course_id })
    if (status === 200) {
      if (data.status) {

        toast.success(data.message);
        getAllCourses(3);
      }
    } else {

      toast.error(data.message);
    }
    setRemoveConfirmationModal({ course_id: null, status: false });
  };

  const handleExport = (excel_type) => {
    if (!courseList || courseList.length === 0) {
      toast.error("Course list is empty!");
      return;
    }
    if (courseList?.length > 0) {
      let newSheetdata = [];
      courseList.map((obj, index) => {
        const newData = {
          SN: parseInt(index) + 1,
          course_id: obj?.course_id || '-',
          course_title: capitalizeFirstLetter(obj?.course_title) || '-',
          course_desc: capitalizeFirstLetter(obj?.course_desc) || '-',


          course_category: (obj?.course_category) || '-',
          course_price: (obj?.course_price) || '-',
          course_platform: (obj?.course_platform) || '-',
          course_duration: (obj?.course_duration) || '-',
          course_rating: (obj?.course_rating) || '-',
          course_reviews: (obj?.course_reviews) || '-',
          course_progress: (obj?.course_progress) || '-',

          course_created_at: moment(obj?.course_created_at).format("DD-MM-YYYY") || '-',
          Status: obj?.course_is_active == true ? 'Active' : 'In-Active' || '-',
          user_username: obj?.course_cu_relation?.user_username || '-',
        }
        newSheetdata.push(newData);
      });
      let currentDate = new Date();
      let fileName = 'Course_Excel_Report_' + moment(currentDate).format("DD-MM-YYYY") + '.' + excel_type;
      const headings = [['SN', 'Course ID', 'Course Name', 'Course Description', 'Created Date', 'Status', 'Created By']];
      const wb = utils.book_new();
      const ws = utils.json_to_sheet([]);
      utils.sheet_add_aoa(ws, headings);
      utils.sheet_add_json(ws, newSheetdata, { origin: 'A2', skipHeader: true });
      utils.book_append_sheet(wb, ws, 'Report');
      writeFile(wb, fileName);
    } else {
      toast.error("Data Not Found!");
    }
  }

  return isLoading ? (
    <FallingLinesLoader />
  ) : (
    <div className="px-4 sm:px-4 lg:px-4">
      <ConfirmationModal
        title={
          "Are you sure you want to " +
          (confirmationModal.course_is_active ? "Disable " : "Enable") +
          " this Course?"
        }
        confirmationButtonText="Yes"
        open={confirmationModal.status}
        onDelete={() =>
          onStatusUpdate(
            confirmationModal.course_id,
            confirmationModal.course_is_active
          )
        }
        setOpen={setConfirmationModal}
      />

      <DeleteConfirmationModal
        title={
          "Are you sure you want to " +
          (removeConfirmationModal.course_is_deleted
            ? " Undo"
            : "Delete") +
          " this Course?"
        }
        confirmationButtonText="Yes"
        open={removeConfirmationModal.status}
        onDelete={() => {
          console.log("confirmationModal = ", removeConfirmationModal);
          onDeleteCourse(removeConfirmationModal.course_id);
        }}
        setOpen={setRemoveConfirmationModal}
      />

      <Breadcrumb pages={pages} />
      <div className="">
        <h1 className="text-xl font-semibold text-white">Course List</h1>
      </div>
      <div className="mt-4 flex">
        {/* {allowedActions.filter((data) => data.permission_id == 28)?.length >
          0 ? ( */}
        <Link
          onClick={() => handleDrawer("add", "", {})}
          type="button"
          className="inline-flex items-center justify-center rounded-md bbt bg-[#27ff10] px-4 py-2 text-sm font-medium text-black shadow-sm hffff  ml-auto"
        >
          <PlusCircleIcon className="-ml-1.5 h-5 w-5 mr-1" aria-hidden="true" />
          Add Course
        </Link>
        {/* ) : null} */}
        {/* {allowedActions.filter((data) => data.permission_id == 47)?.length > 0 && courseList?.length > 0 ? ( */}
        <Link
          to={"#"}
          onClick={() => handleExport('xlsx')}
          type="button"
          className="inline-flex items-center justify-center rounded-md bbt bg-[#27ff10] px-4 py-2 text-sm font-medium text-black shadow-sm hffff  ml-2"
        >
          <ArrowDownCircleIcon className="-ml-1.5 h-5 w-5 mr-1" aria-hidden="true" />
          Download Excel
        </Link>
        {/* ) : null} */}
        {/* {allowedActions.filter((data) => data.permission_id == 48)?.length > 0 ? ( */}
        <> <button
          className="inline-flex items-center justify-center rounded-md bbt bg-[#27ff10] px-4 py-2 text-sm font-medium text-black shadow-sm hffff  ml-2"
          disabled={importLoader}
          onClick={handleCourseButtonClick}
          aria-live="polite" // Optional for screen readers
        >
          {importLoader ? (
            <>
              <FaSpinner color="gold" className="spinner animate-spin" />
              <span className="ml-2">Uploading Please Wait ...</span>
            </>
          ) : (
            "Upload Course"
          )}
        </button>
          <input
            accept=".xlsx, .xls"
            onChange={handleCourseImport}
            type="file"
            name="user_excel"
            autoComplete="off"
            ref={fileInputRef}
            className="block w-full bg-white max-w-lg rounded-md border-[1px] p-2 border-gray-300 shadow-sm focus:border-[1px] focus:border-cyan-500 sm:max-w-xs sm:text-sm"
            style={{ display: 'none' }} // Hide the file input
          /></>
        {/* ) : null} */}
      </div>

      {isLoading ? (
        <FallingLinesLoader />
      ) : (
        <Table
          columns={course_columns({
            chapterModalOpen,
            onDeleteOpen,
            onDeleteOpenSection,
            handleDrawer,
            allowedActions,
          })}
          data={courseList}
          is_toggle={false}
        />
      )}

      <div>
        <Transition.Root show={modalOpenFlage} as={Fragment}>
          <Dialog
            as="div"
            className="relative z-10"
            onClose={setmodalOpenFlage}
          >
            <div className="fixed inset-0" />
            <div className="fixed inset-0 overflow-hidden">
              <div className="absolute inset-0 overflow-hidden">
                <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10 sm:pl-16">
                  <Transition.Child
                    as={Fragment}
                    enter="transform transition ease-in-out duration-500 sm:duration-700"
                    enterFrom="translate-x-full"
                    enterTo="translate-x-0"
                    leave="transform transition ease-in-out duration-500 sm:duration-700"
                    leaveFrom="translate-x-0"
                    leaveTo="translate-x-full"
                  >
                    <Dialog.Panel className="pointer-events-auto w-screen max-w-xs">
                      <form
                        onSubmit={(e) => {
                          e.preventDefault();
                          handleSubmit(e);
                        }}
                        className="flex h-full flex-col divide-y divide-gray-700 bg-gray-800 shadow-lg"
                      >
                        <div className="h-0 flex-1 overflow-y-auto">
                          <div className="bg-gradient-to-b from-[#2e2e34] from-10% via-gray-900 via-9% to-gray-900 to-80% py-6 px-4 sm:px-6">
                            <div className="flex items-center justify-between">
                              <Dialog.Title className="text-lg font-medium text-white">
                                {course_id ? "Update" : "Add"} Course
                              </Dialog.Title>
                              <div className="ml-3 flex h-7 items-center">
                                <button
                                  type="button"
                                  className="rounded-md text-cyan-200 hover:text-white"
                                  onClick={() => setmodalOpenFlage(false)}
                                >
                                  <span className="sr-only">Close panel</span>
                                  <XMarkIcon
                                    className="h-6 w-6"
                                    aria-hidden="true"
                                  />
                                </button>
                              </div>
                            </div>
                          </div>

                          <div className="flex flex-1 flex-col justify-between">
                            <div className="p-4 sm:p-6">
                              <div className="mt-2 mb-2 sm:col-span-2 sm:mt-0">
                                <div>
                                  <label
                                    htmlFor="course_title"
                                    className="block text-sm font-medium text-gray-300 mb-1"
                                  >
                                    Course Name<span className="text-red-600">*</span>

                                  </label>
                                </div>
                                <div className="mt-2 mb-3 sm:col-span-2 sm:mt-0">
                                  <input
                                    value={values.course_title}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    type="text"
                                    placeholder="Course Name"
                                    name="course_title"
                                    autoComplete="off"
                                    className="block w-full max-w-lg rounded-md border-[1px] p-2 border-gray-600 bg-gray-700 text-white shadow-sm focus:border-[1px] focus:border-cyan-500 sm:max-w-xs sm:text-sm"
                                  />
                                  {errors.course_title && touched.course_title ? (
                                    <p className="text-red-600 text-sm">
                                      {errors.course_title.replace("course_title", "Course Name")}
                                    </p>
                                  ) : null}
                                </div>
                              </div>

                              <div className="mt-2 mb-2 sm:col-span-2 sm:mt-0">
                                <FileUploadWithPreview
                                  onFileUpload={handleCourseFileUpload}
                                  bannerName="Course Upload"
                                  maxFileSize={500}
                                  FolderName={'course'}
                                  multiple={false}
                                  doc_id={course_id}
                                />
                                {values.cousre_imag_url}
                                {errors.cousre_imag_url && touched.cousre_imag_url ? (
                                  <p className="text-red-600 text-sm">
                                    {errors.cousre_imag_url.replace("cousre_imag_url", "Course Image")}
                                  </p>
                                ) : null}
                              </div>

                              <div className="mt-2 mb-2 sm:col-span-2 sm:mt-0">
                                <div>
                                  <label
                                    htmlFor="course_desc"
                                    className="block text-sm font-medium text-gray-300 mb-1"
                                  >
                                    Course Desc.

                                  </label>
                                </div>
                                <div className="mt-2 mb-3 sm:col-span-2 sm:mt-0">
                                  <textarea
                                    rows="4"
                                    value={values.course_desc}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    type="text"
                                    placeholder="Course Desc."
                                    name="course_desc"
                                    autoComplete="off"
                                    className="block w-full max-w-lg rounded-md border-[1px] p-2 border-gray-600 bg-gray-700 text-white shadow-sm focus:border-[1px] focus:border-cyan-500 sm:max-w-xs sm:text-sm"
                                  />
                                  {errors.course_desc && touched.course_desc ? (
                                    <p className="text-red-600 text-sm">
                                      {errors.course_desc.replace("course_desc", "Course Desc.")}
                                    </p>
                                  ) : null}
                                </div>
                              </div>

                              <div className="mt-2 mb-2 sm:col-span-2 sm:mt-0">
                                <div>
                                  <label
                                    htmlFor="course_category"
                                    className="block text-sm font-medium text-gray-300 mb-1"
                                  >
                                    Course Category<span className="text-red-600"></span>

                                  </label>
                                </div>
                                <div className="mt-2 mb-3 sm:col-span-2 sm:mt-0">
                                  <input
                                    value={values.course_category}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    type="text"
                                    placeholder="Course Category"
                                    name="course_category"
                                    autoComplete="off"
                                    className="block w-full max-w-lg rounded-md border-[1px] p-2 border-gray-600 bg-gray-700 text-white shadow-sm focus:border-[1px] focus:border-cyan-500 sm:max-w-xs sm:text-sm"
                                  />
                                  {errors.course_category && touched.course_category ? (
                                    <p className="text-red-600 text-sm">
                                      {errors.course_category.replace("course_category", "Course Category")}
                                    </p>
                                  ) : null}
                                </div>
                              </div>

                              <div className="mt-2 mb-2 sm:col-span-2 sm:mt-0">
                                <div>
                                  <label
                                    htmlFor="course_platform"
                                    className="block text-sm font-medium text-gray-300 mb-1"
                                  >
                                    Course Platform<span className="text-red-600"></span>

                                  </label>
                                </div>
                                <div className="mt-2 mb-3 sm:col-span-2 sm:mt-0">
                                  <input
                                    value={values.course_platform}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    type="text"
                                    placeholder="Course Platform"
                                    name="course_platform"
                                    autoComplete="off"
                                    className="block w-full max-w-lg rounded-md border-[1px] p-2 border-gray-600 bg-gray-700 text-white shadow-sm focus:border-[1px] focus:border-cyan-500 sm:max-w-xs sm:text-sm"
                                  />
                                  {errors.course_platform && touched.course_platform ? (
                                    <p className="text-red-600 text-sm">
                                      {errors.course_platform.replace("course_platform", "Course Platform")}
                                    </p>
                                  ) : null}
                                </div>
                              </div>

                              <div className="mt-2 mb-2 sm:col-span-2 sm:mt-0">
                                <div>
                                  <label
                                    htmlFor="course_price"
                                    className="block text-sm font-medium text-gray-300 mb-1"
                                  >
                                    Course Price<span className="text-red-600"></span>

                                  </label>
                                </div>
                                <div className="mt-2 mb-3 sm:col-span-2 sm:mt-0">
                                  <input
                                    value={values.course_price}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    type="number"
                                    placeholder="Course Price"
                                    name="course_price"
                                    autoComplete="off"
                                    className="block w-full max-w-lg rounded-md border-[1px] p-2 border-gray-600 bg-gray-700 text-white shadow-sm focus:border-[1px] focus:border-cyan-500 sm:max-w-xs sm:text-sm"
                                  />
                                  {errors.course_price && touched.course_price ? (
                                    <p className="text-red-600 text-sm">
                                      {errors.course_price.replace("course_price", "Course Price")}
                                    </p>
                                  ) : null}
                                </div>
                              </div>

                              <div className="mt-2 mb-2 sm:col-span-2 sm:mt-0">
                                <div>
                                  <label
                                    htmlFor="course_duration"
                                    className="block text-sm font-medium text-gray-300 mb-1"
                                  >
                                    Course Duration<span className="text-red-600"></span>

                                  </label>
                                </div>
                                <div className="mt-2 mb-3 sm:col-span-2 sm:mt-0">
                                  <input
                                    value={values.course_duration}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    type="text"
                                    placeholder="Course Duration"
                                    name="course_duration"
                                    autoComplete="off"
                                    className="block w-full max-w-lg rounded-md border-[1px] p-2 border-gray-600 bg-gray-700 text-white shadow-sm focus:border-[1px] focus:border-cyan-500 sm:max-w-xs sm:text-sm"
                                  />
                                  {errors.course_duration && touched.course_duration ? (
                                    <p className="text-red-600 text-sm">
                                      {errors.course_duration.replace("course_duration", "Course Duration")}
                                    </p>
                                  ) : null}
                                </div>
                              </div>
                              {/* <div className="mt-2 mb-2 sm:col-span-2 sm:mt-0">
                                <div>
                                  <label
                                    htmlFor="course_rating"
                                    className="block text-sm font-medium text-gray-300 mb-1"
                                  >
                                    Course Rating<span className="text-red-600"></span>

                                  </label>
                                </div>
                                <div className="mt-2 mb-3 sm:col-span-2 sm:mt-0">
                                  <input
                                    value={values.course_rating}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    type="number"
                                    placeholder="Course Rating"
                                    name="course_rating"
                                    autoComplete="off"
                                    className="block w-full max-w-lg rounded-md border-[1px] p-2 border-gray-600 bg-gray-700 text-white shadow-sm focus:border-[1px] focus:border-cyan-500 sm:max-w-xs sm:text-sm"
                                  />
                                  {errors.course_rating && touched.course_rating ? (
                                    <p className="text-red-600 text-sm">
                                      {errors.course_rating.replace("course_rating", "Course Rating")}
                                    </p>
                                  ) : null}
                                </div>
                              </div>
                              <div className="mt-2 mb-2 sm:col-span-2 sm:mt-0">
                                <div>
                                  <label
                                    htmlFor="course_reviews"
                                    className="block text-sm font-medium text-gray-300 mb-1"
                                  >
                                    Course Reviews<span className="text-red-600"></span>

                                  </label>
                                </div>
                                <div className="mt-2 mb-3 sm:col-span-2 sm:mt-0">
                                  <input
                                    value={values.course_reviews}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    type="text"
                                    placeholder="Course Reviews"
                                    name="course_reviews"
                                    autoComplete="off"
                                    className="block w-full max-w-lg rounded-md border-[1px] p-2 border-gray-600 bg-gray-700 text-white shadow-sm focus:border-[1px] focus:border-cyan-500 sm:max-w-xs sm:text-sm"
                                  />
                                  {errors.course_reviews && touched.course_reviews ? (
                                    <p className="text-red-600 text-sm">
                                      {errors.course_reviews.replace("course_reviews", "Course Reviews")}
                                    </p>
                                  ) : null}
                                </div>
                              </div> */}
                              {/* <div className="mt-2 mb-2 sm:col-span-2 sm:mt-0">
                                <div>
                                  <label
                                    htmlFor="course_progress"
                                    className="block text-sm font-medium text-gray-300 mb-1"
                                  >
                                    Course Progress<span className="text-red-600"></span>

                                  </label>
                                </div>
                                <div className="mt-2 mb-3 sm:col-span-2 sm:mt-0">
                                  <input
                                    value={values.course_progress}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    type="text"
                                    placeholder="Course Progress"
                                    name="course_progress"
                                    autoComplete="off"
                                    className="block w-full max-w-lg rounded-md border-[1px] p-2 border-gray-600 bg-gray-700 text-white shadow-sm focus:border-[1px] focus:border-cyan-500 sm:max-w-xs sm:text-sm"
                                  />
                                  {errors.course_progress && touched.course_progress ? (
                                    <p className="text-red-600 text-sm">
                                      {errors.course_progress.replace("course_progress", "Course Progress")}
                                    </p>
                                  ) : null}
                                </div>
                              </div> */}



                              <div className="mt-1 pt-2 pb-2 sm:col-span-2 sm:mt-0">
                                <div className="basis-1/2 text-red-500 text-sm">
                                  {errorMessage !== "" && "Error: " + errorMessage}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="flex flex-shrink-0 justify-end px-4 py-4">
                          <button
                            type="button"
                            className="rounded-md border border-gray-600 bg-gray-700 py-2 px-4 text-sm font-medium text-gray-300 shadow-sm hover:bg-gray-600 focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-2"
                            onClick={() => {
                              setmodalOpenFlage(false);
                            }}
                          >
                            Cancel
                          </button>
                          <button
                            disabled={disableSubmitButton}
                            type="submit"
                            className={`ml-4 inline-flex items-center justify-center rounded-md bbt bg-[#27ff10] px-4 py-2 text-sm font-medium text-black shadow-sm hffff  ml-2 ${disableSubmitButton
                              ? "bg-gray-700 hover:bg-gray-700"
                              : ""
                              }`}
                          >
                            {course_id ? (
                              disableSubmitButton ? (
                                <FaSpinner color="gold" />
                              ) : (
                                "Update"
                              )
                            ) : disableSubmitButton ? (
                              <FaSpinner color="gold" />
                            ) : (
                              "Add"
                            )}
                          </button>
                        </div>
                      </form>
                    </Dialog.Panel>
                  </Transition.Child>
                </div>
              </div>
            </div>
          </Dialog>
        </Transition.Root>

        <Transition.Root show={chapterModal} as={Fragment}>
          <Dialog as="div" className="relative z-10" onClose={setChapterModal}>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </Transition.Child>

            <div className="fixed inset-0 z-10 overflow-y-auto">
              <div className="flex min-h-full items-center justify-center p-4 text-center sm:items-center sm:p-0">
                <Transition.Child
                  as={Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                  enterTo="opacity-100 translate-y-0 sm:scale-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                  leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                >
                  <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:w-full sm:max-w-md">
                    <div className="flex items-center text-lg font-extrabold px-4 text-white py-2 bg-gradient-to-b from-[#27ff10] to-[#27ff10] italic justify-between">
                      <h1 className="text-lg font-medium text-white">
                        Add Chapter for {singleCourse ? singleCourse.course_title : null}
                      </h1>
                      <XMarkIcon
                        className="text-white h-8 cursor-pointer w-8 ml-auto"
                        aria-hidden="true"
                        onClick={() => {
                          setChapterModal(false);
                        }}
                      />
                    </div>
                    <div className="p-5">
                      <Formik
                        initialValues={formChapter}
                        validationSchema={chapterMasterSchema}
                        onSubmit={(values, { setSubmitting }) => {
                          console.log('currentCourseID=>', currentCourseID)
                          const payload = {
                            chapter_id: 0, chapter_title: values.chapter_title, duration: values.duration, chapter_time: values.chapter_time, chapter_desc: values.chapter_desc, chapter_path: values.chapter_path || uploadedFiles, chapter_type: values.chapter_type, fk_course_id: currentCourseID
                          }
                          if (payload.course_title != undefined || payload.course_title != null || payload.course_title != "") {
                            setTimeout(() => {
                              setDisableSubmitButton(false)
                            }, 1000)
                            setDisableSubmitButton(true)
                            chapterServices.SaveChapter(payload).then((res) => {
                              const { data, status, message } = res.data;
                              if (status) {

                                setCurrentCourseID(0);
                                setChapterModal(false)
                                const chapterInitialValuse = {
                                  chapter_id: "",
                                  chapter_title: "",
                                  chapter_type: "",
                                  duration: "",
                                  chapter_time: "",
                                  chapter_desc: "",
                                  chapter_path: "",
                                  fk_course_id: "",
                                }
                                setFormChapter(chapterInitialValuse);
                                toast.success(message);
                                setDisableSubmitButton(false)
                                //action.resetForm();
                              } else {
                                toast.error(message);
                                setDisableSubmitButton(false)
                              }
                            }).catch((error) => {
                              console.log(error)
                              setDisableSubmitButton(false)
                            });
                          }

                        }}
                      >
                        {({
                          values,
                          errors,
                          touched,
                          handleChange,
                          handleBlur,
                          handleSubmit,
                          isSubmitting,
                          /* and other goodies */
                        }) => (
                          <form
                            onSubmit={(e) => {
                              e.preventDefault();
                              handleSubmit(e);
                            }}
                          // className="flex h-full flex-col divide-y divide-gray-200 bg-white shadow-xl"
                          >

                            <div className="my-2 sm:col-span-2 sm:mt-0">
                              <div>
                                <label
                                  htmlFor="chapter_title"
                                  className="block text-sm font-medium text-gray-900 mb-1"
                                >
                                  Name<span className="text-red-600">*</span>
                                </label>
                              </div>
                              <div className="mt-2 mb-3 sm:col-span-2 sm:mt-0">
                                <input
                                  value={values.chapter_title}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  type="text"
                                  placeholder="Name"
                                  name="chapter_title"
                                  autoComplete="off"
                                  className="block w-full max-w-lg rounded-md border-[1px] p-2 border-gray-300 shadow-sm focus:border-[1px] focus:border-[#BE3144] sm:text-sm"
                                />
                                {errors.chapter_title &&
                                  touched.chapter_title ? (
                                  <p className="text-red-600 text-sm">
                                    {errors.chapter_title}
                                  </p>
                                ) : null}
                              </div>
                            </div>

                            <div className="my-2 sm:col-span-2 sm:mt-0">
                              <div>
                                <label
                                  htmlFor="chapter_period"
                                  className="block text-sm font-medium text-gray-900 mb-1"
                                >
                                  Type<span className="text-red-600">*</span>
                                </label>
                              </div>
                              <div className="mt-2 mb-3 sm:col-span-2 sm:mt-0">

                                <Select
                                  name="chapter_type"
                                  placeholder={
                                    <span>
                                      Select Type
                                      <span className="text-red-600">
                                        *
                                      </span>
                                    </span>
                                  }
                                  onChange={(e) => {
                                    values.chapter_type = e.value;
                                    handleChapterType(e);
                                  }}
                                  value={selectedChapter}
                                  components={animatedComponents}
                                  options={chaptersTypeList}
                                  classNamePrefix="select"
                                  className="basic-multi-select block w-full rounded-md border-[1px]  shadow-sm  focus:border-red-500 sm:text-sm"
                                />

                                {errors.chapter_type && touched.chapter_type ? (
                                  <p className="text-red-600 text-sm">{errors.chapter_type}</p>
                                ) : null}
                              </div>
                            </div>



                            {["AUDIO", "VIDEO"].includes(values.chapter_type) && (
                              <div className="my-2 sm:col-span-2 sm:mt-0">
                                <div>
                                  <label
                                    htmlFor="chapter_path"
                                    className="block text-sm font-medium text-gray-900 mb-1"
                                  >
                                    {values.chapter_type} URL<span className="text-red-600">*</span>
                                  </label>
                                </div>
                                <div className="mt-2 mb-3 sm:col-span-2 sm:mt-0">
                                  <input
                                    value={values.chapter_path}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    type="text"
                                    placeholder="Chpater URL"
                                    name="chapter_path"
                                    autoComplete="off"
                                    className="block w-full max-w-lg rounded-md border-[1px] p-2 border-gray-300 shadow-sm focus:border-[1px] focus:border-[#BE3144] sm:text-sm"
                                  />
                                  {errors.chapter_path &&
                                    touched.chapter_path ? (
                                    <p className="text-red-600 text-sm">
                                      {errors.chapter_path}
                                    </p>
                                  ) : null}
                                </div>
                              </div>)}

                            {values.chapter_type === "FILE" && (
                              <div>
                                <FileUploadWithPreview
                                  onFileUpload={handleFileUpload}
                                  bannerName="Chapter Upload"
                                  maxFileSize={500}
                                  FolderName={'chapter'}
                                  multiple={false}
                                  doc_id={0}
                                />
                                {errors.chapter_path && touched.chapter_path ? (
                                  <p className="text-red-600 text-sm">
                                    {errors.chapter_path.replace("chapter_path", "Chapter File")}
                                  </p>
                                ) : null}
                              </div>
                            )}

                            {(values.chapter_type === "AUDIO" || values.chapter_type === "VIDEO") && (<div className="my-2 sm:col-span-2 sm:mt-0">
                              <div>
                                <label
                                  htmlFor="duration"
                                  className="block text-sm font-medium text-gray-900 mb-1"
                                >
                                  Duration<span className="text-red-600">*</span>
                                </label>
                              </div>
                              <div className="mt-2 mb-3 sm:col-span-2 sm:mt-0">
                                <input
                                  value={values.duration}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  type="number"
                                  placeholder="Duration"
                                  name="duration"
                                  autoComplete="off"
                                  className="block w-full max-w-lg rounded-md border-[1px] p-2 border-gray-300 shadow-sm focus:border-[1px] focus:border-[#BE3144] sm:text-sm"
                                />
                                {errors.duration &&
                                  touched.duration ? (
                                  <p className="text-red-600 text-sm">
                                    {errors.duration}
                                  </p>
                                ) : null}
                              </div>
                            </div> )}
                            {values.chapter_type === "TEXT" && (<div className="my-2 sm:col-span-2 sm:mt-0">
                              <div>
                                <label
                                  htmlFor="chapter_desc"
                                  className="block text-sm font-medium text-gray-900 mb-1"
                                >
                                  Desc
                                </label>
                              </div>
                              <div className="mt-2 mb-3 sm:col-span-2 sm:mt-0">
                                <textarea
                                  value={values.chapter_desc}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  placeholder="Description"
                                  name="chapter_desc"
                                  autoComplete="off"
                                  className="block w-full max-w-lg rounded-md border-[1px] p-2 border-gray-300 shadow-sm focus:border-[1px] focus:border-[#BE3144] sm:text-sm"
                                  rows="2"
                                />
                                {errors.chapter_desc &&
                                  touched.chapter_desc ? (
                                  <p className="text-red-600 text-sm">
                                    {errors.chapter_desc}
                                  </p>
                                ) : null}
                              </div>
                            </div>)}

                            <div className="mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3">
                              <button
                                type="submit"

                                className="inline-flex w-full justify-center rounded-md bg-gradient-to-b from-[#27ff10] to-[#27ff10] px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-gradient-to-b focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-[#27ff10] sm:col-start-2"
                                // onClick={() => chapterModalOpen(false)}
                                disabled={disableSubmitButton}
                              >

                                {(disableSubmitButton ? <FaSpinner color="gold" /> : "Add")}

                              </button>
                              <button
                                type="button"
                                className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:col-start-1 sm:mt-0"
                                onClick={() => setChapterModal(false)}
                              >
                                Cancel
                              </button>
                            </div>
                          </form>
                        )}
                      </Formik>
                    </div>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </Dialog>
        </Transition.Root>

      </div>
    </div>
  );
}
export default CourseList;