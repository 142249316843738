/* eslint-disable */
import Api from "./Api";
export default {

  //  param Section Start
  async SaveParam(payload) {
    const response = await Api().post(`api/dashboard/params`, payload);
    return response;
  },

  async getAllParam(payload) {
    let isObj= payload !== null && typeof payload === "object" && !Array.isArray(payload);
    const params = new URLSearchParams(payload).toString();
    const response = await Api().get(isObj?`api/dashboard/params/?${params}`:`api/dashboard/params/?search=${payload}`);
    return response;
  },
  async getAllSingleParam(payload) {
    const response = await Api().get(`api/dashboard/params/${payload}`);
    return response;
  },
  async setParamStatus(payload) {
    const response = await Api().post(`api/dashboard/params/status`, payload);
    return response;
  },
  async deleteParam(payload) {
    const response = await Api().post(`api/dashboard/params/remove`, payload);
    return response;
  },

  async importParam(payload) {
    const response = await Api().post(`api/dashboard/params/import`, payload);
    return response;
  },
  //  param Section End
}