/* eslint-disable */
import Api from "./Api";
// import AuthToken from "./AuthTokenServices";
export default {


  //  product Section Start
  async SaveAccount(payload) {
    // await AuthToken.getCurrentAuth();
    const response = await Api().post(`api/dashboard/account`, payload);
    return response;
  },

  async getAllAccount(payload) {
    // await AuthToken.getCurrentAuth();
    let isObj = payload !== null && typeof payload === "object" && !Array.isArray(payload);
    const params = new URLSearchParams(payload).toString();
    const response = await Api().get(isObj ? `api/dashboard/account/?${params}` : `api/dashboard/account/?search=${payload}`);
    return response;
  },
  async getAllSingleAccount(payload) {
    // await AuthToken.getCurrentAuth();
    const response = await Api().get(`api/dashboard/account/${payload}`);
    return response;
  },
  async setAccountStatus(payload) {
    // await AuthToken.getCurrentAuth();
    const response = await Api().post(`api/dashboard/account/status`, payload);
    return response;
  },
  async updateConfigData(payload) {
    // await AuthToken.getCurrentAuth();
    const response = await Api().post(`api/dashboard/account/update-param-config`, payload);
    return response;
  },
  async deleteAccount(payload) {
    // await AuthToken.getCurrentAuth();
    const response = await Api().post(`api/dashboard/account/remove`, payload);
    return response;
  },

  async importAccount(payload) {
    // await AuthToken.getCurrentAuth();
    const response = await Api().post(`api/dashboard/account/import`, payload);
    return response;
  },



  //  product Section End
}