import React, { useEffect, useState, Fragment, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import Table from "../../../components/tables/table";
import { customer_columns } from "../../../components/tables/tableheader";
import { customerSchema } from "../../../schemas";
import { Dialog, Transition } from "@headlessui/react";
import Pagination from "../../../components/Pagination";
import { FallingLinesLoader } from "../../../components/spinners/Spinner";
import Breadcrumb from "../../../components/Breadcrumb";
import ConfirmationModal from "../../../components/ConfirmationModal";
import DeleteConfirmationModal from "../../../components/DeleteConfirmationModal";
import { XMarkIcon } from "@heroicons/react/24/outline";
import MasterServices from "../../../ApiServices/MasterServices";
import CustomerServices from "../../../ApiServices/CustomerServices";
import { toast } from "react-toast";
import { FaSpinner } from "react-icons/fa";
import makeAnimated from 'react-select/animated';
import moment from 'moment';
import { utils, writeFile, read } from 'xlsx';
import { getAllowedActions, checkPermissionsAndRedirect, capitalizeFirstLetter } from "../../../helper/commonHelper";
import FSLoader from "../../../components/FSLoader";
import { handleDynamicFileImport } from '../../../helper/dynamicImport';


function CustomerList() {
  const pages = [{ title: "Customer List", href: "/customer", module_id: 2 }];
  const animatedComponents = makeAnimated();
  const [modalOpenFlage, setmodalOpenFlage] = useState(false);
  const [cust_id, setCustomerid] = useState("");
  const [userList, setUserList] = useState([]);
  const [banner, setBanner] = useState(null);
  const [tempBanner, setTempBanner] = useState(null);
  const [base64banner, setBase64banner] = useState(null);
  const [imageUrl, setImageUrl] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState('');
  const [isFSLoading, setSFLoading] = useState(false);
  const [importLoader, setImportLoader] = useState(false);
  const fileInputRef = useRef(null);

  const [error, setError] = useState(null);

  const [disableSubmitButton, setDisableSubmitButton] = useState(false);
  const [confirmationModal, setConfirmationModal] = useState({
    status: false,
    cust_id: null,
  });

  const [confirmationBanModal, setConfirmationBanModal] = useState({
    status: false,
    cust_id: null,
  });

  const [removeConfirmationModal, setRemoveConfirmationModal] = useState({
    status: false,
    cust_id: null,
  });

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [allowedActions, setAllowedActions] = useState([]);

  const entityCustomerMap = {
    FirstName: 'FirstName',
    MiddleName: 'MiddleName',
    LastName: 'LastName',
    Username: 'Username',
    Email: 'Email',
    Mobile: 'Mobile',
    Password: 'Password',
    Gender: 'Gender',
    DOB: 'DOB',
  };

  // // Define the entity you are working with (in this case 'barcode')
  const entityCustomer = "Customer";

  useEffect(() => {
    const permissions = JSON.parse(localStorage.getItem("user_permissions"));
    if (permissions && window.location.pathname) {
      const checkPermissionsAndRedirectFunc = checkPermissionsAndRedirect(window.location.pathname, permissions, navigate);
      const actions = getAllowedActions(permissions, window.location.pathname);
      setAllowedActions(actions);
    }
  }, []);

  useEffect(() => {
    document.title = "EdgeFin •  Customers";
    getAllCustomers(3);
  }, []);

  const getAllCustomers = async (payload) => {
    setIsLoading(true);
    try {
      const res = await CustomerServices.getAllCustomer(payload)
      if (res.status) {
        setUserList(res?.data?.data?.customers);
        // toast.success(res?.data?.message);
      }
      setIsLoading(false);
    } catch (e) {
      console.log(e, "error in getAllModules")
      toast.error("Fatal Error")
      setIsLoading(false);
    }
  }

  const onStatusUpdate = (cust_id, cust_is_active) => {
    let payload = {
      table_name: "m_customer",
      table_field: "cust_id",
      table_field_val: cust_id,
      table_status_field: "cust_is_active",
      table_status_val: cust_is_active ? false : true,
      table_text: "Customer",
      deleted_by_key: "cust_uu_by",
      delete_active_txt: cust_is_active ? " Disabled" : " Enabled",
    }
    MasterServices.removeMaster(payload).then((res) => {
      const { data, status, message } = res.data
      if (status) {
        getAllCustomers(3);
        toast.success(message);
      } else {
        toast.error(message)
      }
    })
  }

  const onCloseTradeStatusUpdate = (pk_id, acc_status, type) => {
    let payload = {
      table_name: "m_customer",
      table_field: "cust_id",
      table_field_val: pk_id,
      table_status_field: type, //"lam_is_banned",
      table_status_val: acc_status ? false : true,
      table_text: "Customer",
      deleted_by_key: "cust_uu_by",
      delete_active_txt: acc_status ? "Open Trade" : "Close Trade" //acc_status ? " Un-Baned" : " Ban",
    }

    MasterServices.removeMaster(payload).then((res) => {
      const { data, status, message } = res.data
      if (status) {
        getAllCustomers(3);
        toast.success(message);
      } else {
        toast.error(message)
      }
    })
  }

  let initialValues = {
    cust_id: "",
    cust_email: "",
    cust_mobile: "",
    cust_password: "",
    cust_fname: "",
    cust_lname: "",
    cust_pic_url: "",
    cust_username: "",
  };

  const [formCustomer, setFormCustomer] = useState(initialValues);

  const handleDrawer = (type, id, obj) => {
    setBase64banner(null);
    setBanner(null);
    setTempBanner(null);
    if ((type === "edit", id)) {
      setCustomerid(id);
      setImageUrl(obj?.cust_pic_url);
      setTempBanner(obj?.cust_pic_url);
      const initialValues = {
        cust_id: obj.cust_id,
        cust_email: obj.cust_email,
        cust_mobile: obj.cust_mobile,
        cust_password: obj.cust_password,
        cust_fname: obj.cust_fname,
        cust_lname: obj.cust_lname,
        cust_pic_url: base64banner ? base64banner : null,
        cust_username: obj.cust_username,
      };

      setFormCustomer(initialValues);
    } else {
      setCustomerid("");
      setFormCustomer(initialValues);
      setBase64banner(null);
      setBanner(null);
      setTempBanner(null);
    }
    if (modalOpenFlage === false) {
      setmodalOpenFlage(true);
    }
  };

  const onCloseTradeOpen = (pk_id, acc_status, type) => {
    let title = "Are you sure you want to modify?";
    if (type == "cust_is_closed_all_trade") {
      title = "Are you sure you want to " + (confirmationModal.acc_status ? "Open All Trading" : "Close All Trading") + " this Account?";
    } else {
      title = "Are you sure you want to " + (confirmationModal.acc_status ? "Un-Ban (Revoke)" : "Ban") + " this Account?";
    }
    setConfirmationBanModal({ pk_id, acc_status, type, status: true, title });
  };

  const onDeleteOpen = (cust_id, cust_is_active) => {
    setConfirmationModal({ cust_id, cust_is_active, status: true });
  };

  const onDeleteOpenSection = (cust_id, user_is_deleted) => {
    setRemoveConfirmationModal({ cust_id, user_is_deleted, status: true });
  };

  const { values, errors, touched, handleBlur, handleChange, handleSubmit } =
    useFormik({
      enableReinitialize: true,
      initialValues: formCustomer,
      validationSchema: customerSchema,
      onSubmit: async (values, action) => {

        let body = {
          cust_id: cust_id,
          cust_email: values.cust_email,
          cust_mobile: values.cust_mobile,
          cust_password: values.cust_password,
          cust_fname: values.cust_fname,
          cust_lname: values.cust_lname,
          cust_pic_url: base64banner ? base64banner : null,
          cust_username: values.cust_username,
        };

        if (body.cust_email != undefined || body.cust_email != null || body.cust_email != "") {
          setUserList([]);
          setIsLoading(true);
          setTimeout(() => {
            setDisableSubmitButton(false)
          }, 1000)
          setDisableSubmitButton(true)
          let { data, status, message } = await CustomerServices.saveCustomer(body)
          if (status === 200) {
            if (data.status) {
              action.resetForm();
              toast.success(data.message);
              getAllCustomers(3);
              setIsLoading(false)
              setmodalOpenFlage(false);
              setDisableSubmitButton(false)
              setBanner(null);
              setTempBanner(null);
              setBase64banner(null);
            } else {
              toast.success(data.message);
              setErrorMessage(data.message);
              setTimeout(() => {
                setErrorMessage('')
              }, 1000)
              setIsLoading(false);
              setDisableSubmitButton(false);
            }
          } else {
            setDisableSubmitButton(false);
          }
        } else {
          setDisableSubmitButton(false)

          action.resetForm();
          if (modalOpenFlage === true) {
            getAllCustomers(3);
            setmodalOpenFlage(false);
          }
          setDisableSubmitButton(false)
        }
      },
    });

  const onFileChange = (e) => {
    const file = e.target.files[0];
    const fileName = file.name;

    const reader = new FileReader();
    reader.onload = (e) => {
      setBanner(file);
      setTempBanner(e.target.result);
      setBase64banner(e.target.result);
    };
    reader.readAsDataURL(file);
  };

  const onDeleteCustomer = async (cust_id) => {
    let { data, status } = await CustomerServices.deleteCustomer({ cust_id })
    if (status === 200) {
      if (data.status) {

        toast.success(data.message);
        getAllCustomers(3);
      }
    } else {

      toast.error(data.message);
    }
    setRemoveConfirmationModal({ cust_id: null, status: false });
  };


  const handleCustomerButtonClick = () => {
    fileInputRef.current.click();
  };

  const handleCustomerImport = (event) => {
    const file = event.target.files[0];

    // File type validation
    if (file && !file.name.match(/\.(xlsx|xls)$/)) {
      setError("Please upload a valid Excel file.");
      return;
    }
    if (file) {
      setImportLoader(true);

      // Call the dynamic import utility function
      handleDynamicFileImport(file, entityCustomerMap)
        .then((importData) => {
          if (importData.length > 0) {
            console.log('importData=>', importData)
            // Call API to save the imported data
            importEntities(importData);
          } else {
            setImportLoader(false);
            toast.warning('No valid data found in the file');
          }
        })
        .catch((error) => {
          setImportLoader(false);
          toast.error(`Error processing file: ${error.message}`);
        });
    } else {
      toast.error('No file selected');
    }
  };

  const importEntities = (data) => {
    const payload = { excel_data: data };
    console.log('importEntities payload=>', payload, data)

    CustomerServices.importCustomer(payload, entityCustomer)
      .then((response) => {
        setImportLoader(false);
        if (response.status === 200) {
          toast.success(`${entityCustomer} imported successfully`);
          getAllCustomers(3);
        } else {
          toast.error(response.data.message);
        }
      })
      .catch((error) => {
        setImportLoader(false);
        toast.error(`Error while importing ${entityCustomer}`);
      });
  };


  const handleExport = (excel_type) => {
    if (!userList || userList.length === 0) {
      toast.error("Customer list is empty!");
      return;
    }
    if (userList?.length > 0) {
      let newSheetdata = [];
      userList.map((obj, index) => {
        const newData = {
          SN: parseInt(index) + 1,
          cust_id: obj?.cust_id || '-',
          cust_fname: capitalizeFirstLetter(obj?.cust_fname) || '-',
          cust_mname: capitalizeFirstLetter(obj?.cust_mname) || '-',
          cust_lname: capitalizeFirstLetter(obj?.cust_lname) || '-',
          cust_username: capitalizeFirstLetter(obj?.cust_username) || '-',
          cust_email: obj?.cust_email || '-',
          cust_mobile: obj?.cust_mobile || '-',
          cust_created_at: moment(obj?.cust_created_at).format("DD-MM-YYYY") || '-',
          Status: obj?.cust_is_active == true ? 'Active' : 'In-Active' || '-',
        }
        newSheetdata.push(newData);
      });
      let currentDate = new Date();
      let fileName = 'Customer_Excel_Report_' + moment(currentDate).format("DD-MM-YYYY") + '.' + excel_type;
      const headings = [['SN', 'Customer ID', 'Customer FName', 'Customer MName', 'Customer LName', 'Customer User Name', 'Customer Email', 'Customer Mobile', 'Created Date', 'Status']];
      const wb = utils.book_new();
      const ws = utils.json_to_sheet([]);
      utils.sheet_add_aoa(ws, headings);
      utils.sheet_add_json(ws, newSheetdata, { origin: 'A2', skipHeader: true });
      utils.book_append_sheet(wb, ws, 'Report');
      writeFile(wb, fileName);
    } else {
      toast.error("Data Not Found!");
    }
  }


  const handleBanUserAcc = (cust_id, user_is_banned) => {
    console.log({ cust_id, user_is_banned });
    setConfirmationBanModal({ cust_id, user_is_banned, status: true });
  }

  const onBanConfirmation = (cust_id, user_is_banned) => {
    setSFLoading(true);
    let payload = {
      user_id: cust_id,
      status: user_is_banned ? false : true
    }
    MasterServices.banCustomerAndAccs(payload).then((res) => {
      const { data, status, message } = res.data;
      setSFLoading(false);
      if (status) {
        getAllCustomers(3);
        toast.success(message);
      } else {
        toast.error(message)
      }
    })
  }

  return isLoading ? (
    <FallingLinesLoader />
  ) : (
    <div className="px-4 sm:px-4 lg:px-4">

      <FSLoader isLoading={isFSLoading} title="Processing..." />

      <ConfirmationModal
        title={
          "Are you sure you want to " +
          (confirmationModal.cust_is_active ? "Disable " : "Enable") +
          " this Customer?"
        }
        confirmationButtonText="Yes"
        open={confirmationModal.status}
        onDelete={() =>
          onStatusUpdate(
            confirmationModal.cust_id,
            confirmationModal.cust_is_active
          )
        }
        setOpen={setConfirmationModal}
      />

      <DeleteConfirmationModal
        title={confirmationBanModal?.title ? confirmationBanModal?.title : "Are you sure you want to modify settings?"}
        confirmationButtonText="Yes"
        open={confirmationBanModal.status}
        onDelete={() =>
          onCloseTradeStatusUpdate(
            confirmationBanModal.pk_id,
            confirmationBanModal.acc_status,
            confirmationBanModal.type
          )
        }
        setOpen={setConfirmationBanModal}
      />

      {/* <ConfirmationModal
        title={
          "Are you sure you want to " +
          (confirmationBanModal.user_is_banned ? "Un-Ban (Revoke)" : "Ban") +
          " this Customer & All Account IDs?"
        }
        confirmationButtonText="Yes"
        open={confirmationBanModal.status}
        onDelete={() =>
          onBanConfirmation(
            confirmationBanModal.cust_id,
            confirmationBanModal.user_is_banned
          )
        }
        setOpen={setConfirmationBanModal}
      /> */}

      {/* <DeleteConfirmationModal
        title={
          "Are you sure you want to " +
          (removeConfirmationModal.user_is_deleted
            ? " Undo"
            : "Delete") +
          " this Customer?"
        }
        confirmationButtonText="Yes"
        open={removeConfirmationModal.status}
        onDelete={() => {
          console.log("confirmationModal = ", removeConfirmationModal);
          onDeleteCustomer(removeConfirmationModal.cust_id);
        }}
        setOpen={setRemoveConfirmationModal}
      /> */}

      <Breadcrumb pages={pages} />
      <div className="">
        <h1 className="text-xl font-semibold text-gray-900">Customer List</h1>
      </div>
      <div className="mt-4 flex">
        {allowedActions.filter((data) => data.permission_id == 22)?.length >
          0 ? (
          <Link
            onClick={() => handleDrawer("add", "", {})}
            type="button"
            className="inline-flex items-center justify-center rounded-md bbt bg-[#27ff10] px-4 py-2 text-sm font-medium text-white shadow-sm hffff  ml-auto"
          >
            Add Customer
          </Link>
        ) : null}
        {allowedActions.filter((data) => data.permission_id == 45)?.length > 0 && userList?.length > 0 ? (
          <Link
            to={"#"}
            onClick={() => handleExport('xlsx')}
            type="button"
            className="inline-flex items-center justify-center rounded-md bbt bg-[#27ff10] px-4 py-2 text-sm font-medium text-white shadow-sm hffff  ml-2"
          >
            Download Excel
          </Link>
        ) : null}
        {allowedActions.filter((data) => data.permission_id == 46)?.length > 0 ? (
          <>
            <button
              className="inline-flex items-center justify-center rounded-md bg-[#27ff10] px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-[#27ff10] focus:outline-none focus:ring-2 focus:ring-[#27ff10] focus:ring-offset-2 ml-2"
              disabled={importLoader}
              onClick={handleCustomerButtonClick}
              aria-live="polite" // Optional for screen readers
            >
              {importLoader ? (
                <>
                  <FaSpinner color="gold" className="spinner animate-spin" />
                  <span className="ml-2">Uploading Please Wait ...</span>
                </>
              ) : (
                "Upload Customer"
              )}
            </button>
            <input
              accept=".xlsx, .xls"
              onChange={handleCustomerImport}
              type="file"
              name="user_excel"
              autoComplete="off"
              ref={fileInputRef}
              className="block w-full bg-white max-w-lg rounded-md border-[1px] p-2 border-gray-300 shadow-sm focus:border-[1px] focus:border-cyan-500 sm:max-w-xs sm:text-sm"
              style={{ display: 'none' }} // Hide the file input
            /></>

        ) : null}
      </div>

      {isLoading ? (
        <FallingLinesLoader />
      ) : (
        <Table
          columns={customer_columns({
            onCloseTradeOpen,
            onDeleteOpen,
            onDeleteOpenSection,
            handleDrawer,
            allowedActions,
            handleBanUserAcc
          })}
          data={userList}
          is_toggle={false}
        />
      )}

      <div>
        <Transition.Root show={modalOpenFlage} as={Fragment}>
          <Dialog
            as="div"
            className="relative z-10"
            onClose={setmodalOpenFlage}
          >
            <div className="fixed inset-0" />
            <div className="fixed inset-0 overflow-hidden">
              <div className="absolute inset-0 overflow-hidden">
                <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10 sm:pl-16">
                  <Transition.Child
                    as={Fragment}
                    enter="transform transition ease-in-out duration-500 sm:duration-700"
                    enterFrom="translate-x-full"
                    enterTo="translate-x-0"
                    leave="transform transition ease-in-out duration-500 sm:duration-700"
                    leaveFrom="translate-x-0"
                    leaveTo="translate-x-full"
                  >
                    <Dialog.Panel className="pointer-events-auto w-screen max-w-xs">
                      <form
                        onSubmit={(e) => {
                          e.preventDefault();
                          handleSubmit(e);
                        }}
                        className="flex h-full flex-col divide-y divide-gray-200 bg-white shadow-xl"
                      >
                        <div className="h-0 flex-1 overflow-y-auto">
                          <div className="bg-gradient-to-b from-[#2e2e34] from-10% via-gray-900 via-9%    to-gray-900 to-80%  py-6 px-4 sm:px-6">
                            <div className="flex items-center justify-between">
                              <Dialog.Title className="text-lg font-medium text-white">
                                {cust_id ? "Update" : "Add"} Customer
                              </Dialog.Title>
                              <div className="ml-3 flex h-7 items-center">
                                <button
                                  type="button"
                                  className="rounded-md  text-cyan-200 hover:text-white"
                                  onClick={() => setmodalOpenFlage(false)}
                                >
                                  <span className="sr-only">Close panel</span>
                                  <XMarkIcon
                                    className="h-6 w-6"
                                    aria-hidden="true"
                                  />
                                </button>
                              </div>
                            </div>
                          </div>

                          <div className="flex flex-1 flex-col justify-between">
                            <div className="p-4 sm:p-6">
                              <div className="mt-2 mb-2 sm:col-span-2 sm:mt-0">
                                <div>
                                  <label
                                    htmlFor="cust_fname"
                                    className="block text-sm font-medium text-gray-900 mb-1"
                                  >
                                    Customer FName
                                    <span className="text-red-600">*</span>
                                  </label>
                                </div>
                                <div className="mt-2 mb-3 sm:col-span-2 sm:mt-0">
                                  <input
                                    value={values.cust_fname}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    type="text"
                                    placeholder="Customer Name"
                                    name="cust_fname"
                                    autoComplete="off"
                                    className="block w-full max-w-lg rounded-md border-[1px] p-2 border-gray-300 shadow-sm focus:border-[1px] focus:border-cyan-500 sm:max-w-xs sm:text-sm"
                                  />
                                  {errors.cust_fname &&
                                    touched.cust_fname ? (
                                    <p className="text-red-600 text-sm">
                                      {errors.cust_fname.replace("cust_fname", "Customer Name")}
                                    </p>
                                  ) : null}
                                </div>
                              </div>
                              <div className="mt-2 mb-2 sm:col-span-2 sm:mt-0">
                                <div>
                                  <label
                                    htmlFor="cust_lname"
                                    className="block text-sm font-medium text-gray-900 mb-1"
                                  >
                                    Customer LName
                                    <span className="text-red-600">*</span>
                                  </label>
                                </div>
                                <div className="mt-2 mb-3 sm:col-span-2 sm:mt-0">
                                  <input
                                    value={values.cust_lname}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    type="text"
                                    placeholder="Customer Last Name"
                                    name="cust_lname"
                                    autoComplete="off"
                                    className="block w-full max-w-lg rounded-md border-[1px] p-2 border-gray-300 shadow-sm focus:border-[1px] focus:border-cyan-500 sm:max-w-xs sm:text-sm"
                                  />
                                  {errors.cust_lname &&
                                    touched.cust_lname ? (
                                    <p className="text-red-600 text-sm">
                                      {errors.cust_lname.replace("cust_lname", "Customer Last Name")}
                                    </p>
                                  ) : null}
                                </div>
                              </div>
                              <div className="mt-2 mb-2 sm:col-span-2 sm:mt-0">
                                <div>
                                  <label
                                    htmlFor="cust_username"
                                    className="block text-sm font-medium text-gray-900 mb-1"
                                  >
                                    Customer User Name
                                    <span className="text-red-600">*</span>
                                  </label>
                                </div>
                                <div className="mt-2 mb-3 sm:col-span-2 sm:mt-0">
                                  <input
                                    value={values.cust_username}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    type="text"
                                    placeholder="Customer User Name"
                                    name="cust_username"
                                    autoComplete="off"
                                    className="block w-full max-w-lg rounded-md border-[1px] p-2 border-gray-300 shadow-sm focus:border-[1px] focus:border-cyan-500 sm:max-w-xs sm:text-sm"
                                  />
                                  {errors.cust_username &&
                                    touched.cust_username ? (
                                    <p className="text-red-600 text-sm">
                                      {errors.cust_username.replace("cust_username", "Customer User Name")}
                                    </p>
                                  ) : null}
                                </div>
                              </div>

                              <div className="mt-2 mb-2 sm:col-span-2 sm:mt-0">
                                <div>
                                  <label
                                    htmlFor="cust_email"
                                    className="block text-sm font-medium text-gray-900 mt-2 mb-1"
                                  >
                                    Customer Email
                                    <span className="text-red-600">*</span>
                                  </label>
                                </div>
                                <div className="mt-2 mb-3 sm:col-span-2 sm:mt-0">
                                  <input
                                    value={values.cust_email}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    type="email"
                                    placeholder="Customer Email"
                                    name="cust_email"
                                    autoComplete="off"
                                    className="block w-full max-w-lg rounded-md border-[1px] p-2 border-gray-300 shadow-sm focus:border-[1px] focus:border-cyan-500 sm:max-w-xs sm:text-sm"
                                    disabled={cust_id ? true : false}
                                  />
                                  {errors.cust_email && touched.cust_email ? (
                                    <p className="text-red-600 text-sm">
                                      {errors.cust_email.replace("cust_email", "Email")}
                                    </p>
                                  ) : null}
                                </div>
                              </div>
                              <div className="mt-2 mb-2 sm:col-span-2 sm:mt-0">
                                <div>
                                  <label
                                    htmlFor="cust_password"
                                    className="block text-sm font-medium text-gray-900 mt-2 mb-1"
                                  >
                                    Password
                                    <span className="text-red-600">*</span>
                                  </label>
                                </div>
                                <div className="mt-2 mb-3 sm:col-span-2 sm:mt-0">
                                  <input
                                    value={values.cust_password}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    type="text"
                                    placeholder="Customer Password"
                                    name="cust_password"
                                    autoComplete="off"
                                    className="block w-full max-w-lg rounded-md border-[1px] p-2 border-gray-300 shadow-sm focus:border-[1px] focus:border-cyan-500 sm:max-w-xs sm:text-sm"
                                    disabled={cust_id ? true : false}
                                  />
                                  {errors.cust_password && touched.cust_password ? (
                                    <p className="text-red-600 text-sm">
                                      {errors.cust_password.replace("cust_password", "Password")}
                                    </p>
                                  ) : null}
                                </div>
                              </div>
                              <div className="mt-2 mb-2 sm:col-span-2 sm:mt-0">
                                <div>
                                  <label
                                    htmlFor="cust_mobile"
                                    className="block text-sm font-medium text-gray-900 mt-2 mb-1"
                                  >
                                    Customer Mobile
                                    <span className="text-red-600">*</span>
                                  </label>
                                </div>
                                <div className="mt-2 mb-3 sm:col-span-2 sm:mt-0">
                                  <input
                                    value={values.cust_mobile}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    type="text"
                                    placeholder="Customer Mobile"
                                    name="cust_mobile"
                                    autoComplete="off"
                                    className="block w-full max-w-lg rounded-md border-[1px] p-2 border-gray-300 shadow-sm focus:border-[1px] focus:border-cyan-500 sm:max-w-xs sm:text-sm"
                                    disabled={cust_id ? true : false}
                                  />
                                  {errors.cust_mobile && touched.cust_mobile ? (
                                    <p className="text-red-600 text-sm">
                                      {errors.cust_mobile.replace("cust_mobile", "Mobile")}
                                    </p>
                                  ) : null}
                                </div>
                              </div>
                              <div className="mt-2 mb-2 sm:col-span-2 sm:mt-0">
                                <div>
                                  <label className="block text-sm font-medium text-gray-900 mb-1">
                                    Upload Profile
                                  </label>
                                </div>
                                <div className="mt-2 mb-3 sm:col-span-2 sm:mt-0">
                                  <input
                                    onChange={(e) => {
                                      handleChange(e);
                                      onFileChange(e);
                                    }}
                                    onBlur={handleBlur}
                                    type="file"
                                    name="cust_pic_url"
                                    className="block w-full max-w-lg rounded-md border-[1px] p-2 border-gray-300 shadow-sm focus:border-[1px] focus:border-cyan-500 sm:max-w-xs sm:text-sm"
                                  />
                                  {errors.cust_pic_url &&
                                    touched.cust_pic_url ? (
                                    <p className="text-red-600 text-sm">
                                      {errors.cust_pic_url}
                                    </p>
                                  ) : null}
                                </div>
                              </div>

                              <div className="col-span-full mb-5">
                                {tempBanner ? (
                                  <img
                                    className="shadow-sm mt-4 w-40"
                                    src={
                                      cust_id
                                        ? imageUrl + tempBanner
                                        : tempBanner
                                    }
                                    alt="preview-banner"
                                  />
                                ) : null}
                              </div>
                              <div className="mt-1 pt-2 pb-2 sm:col-span-2 sm:mt-0">
                                <div className="basis-1/2 text-red-500 text-sm">
                                  {errorMessage !== "" &&
                                    "Error: " + errorMessage}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="flex flex-shrink-0 justify-end px-4 py-4">
                          <button
                            type="button"
                            className="rounded-md border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-2"
                            onClick={() => {
                              setmodalOpenFlage(false);
                            }}
                          >
                            Cancel
                          </button>
                          <button
                            disabled={disableSubmitButton}
                            type="submit"
                            className={`ml-4 inline-flex justify-center rounded-md bbt bg-[#27ff10] py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-cyan-700 focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-2 ${disableSubmitButton
                              ? "bg-gray-700 hover:bg-gray-700"
                              : "bg-cyan-700"
                              }`}
                          >
                            {cust_id ? (
                              disableSubmitButton ? (
                                <FaSpinner color="gold" />
                              ) : (
                                "Update"
                              )
                            ) : disableSubmitButton ? (
                              <FaSpinner color="gold" />
                            ) : (
                              "Add"
                            )}
                          </button>
                        </div>
                      </form>
                    </Dialog.Panel>
                  </Transition.Child>
                </div>
              </div>
            </div>
          </Dialog>
        </Transition.Root>
      </div>
    </div>
  );
}
export default CustomerList;