import React, { useEffect, useState } from "react";
import { useNavigate, useLocation} from "react-router-dom";
import { toast } from "react-toast";
import AuthServices from "../../../ApiServices/AuthServices";
import Api from "../../../ApiServices/MasterServices";
import { FaSpinner } from "react-icons/fa";
import ReCAPTCHA from "react-google-recaptcha";




function disableAutocomplete() {
  const passwordFields = document.querySelectorAll('input[type="password"]');
  // Disable autocomplete for each password field
  passwordFields.forEach(field => {
    field.setAttribute('autocomplete', 'new-password');
  });
}

const Login = () => {

  const location = useLocation();
  const navigate = useNavigate();
  const user = localStorage.getItem("user");
  const [disableSubmitButton, setDisableSubmitButton] = useState(false);
  const [disableSubmitButtonM, setDisableSubmitButtonM] = useState(false);
  const recaptchaRef = React.useRef();


  useEffect(() => {
    disableAutocomplete();
  }, []);
  
  useEffect(() => {
    if (user) {
      navigate("/prod-plan");
    }
  }, [user]);

  const getPermissionsWithRole = async (role_id) => {
    try {
      const res = await Api.getPermissionsByRoleID(role_id);
      if (res.status) {
        let prevIds = [];
        res.data.data.permissions.map((p) => {
          prevIds.push(p.permission_id);
        });
        localStorage.setItem(
          "user_permissions",
          JSON.stringify(res.data.data.permissions)
        );
        return true;
      } else {
        return false;
      }

    } catch (e) {
      console.log(e, "error in getPermissionsWithRole");
      return false;
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const recaptchaValue = recaptchaRef.current.getValue();
    const body = {
      cust_email: e.target.email.value,
      cust_password: e.target.password.value,
      captcha: recaptchaValue ? recaptchaValue : null
    };
    AuthServices.CustomerLogin(body)
      .then(async (response) => {
        const { data, status } = response;
        if (status == 200 && data.status) {
          toast.success(data.message);
          localStorage.setItem("user", JSON.stringify(data?.data?.user));
        
          localStorage.setItem("type", 'Customer');
          localStorage.setItem("module", JSON.stringify(data?.data?.module));
          localStorage.setItem("token", data?.data?.token);
          localStorage.setItem("role_id", data?.data?.user?.role_id);
          localStorage.setItem("user_id", data?.data?.user?.user_id);
          
          recaptchaRef.current?.reset();
          if (data?.data?.user?.role_id) {
            const getPermisson = await getPermissionsWithRole(data?.data?.user?.role_id);

            if (getPermisson) {
              setDisableSubmitButton(false)
              window.location.href = '/prod-plan'
            }
          }
          setDisableSubmitButton(false)
          recaptchaRef.current?.reset();
        } else {
          recaptchaRef.current?.reset();
          toast.error(data.message);
          setDisableSubmitButton(false)
        }
        recaptchaRef.current?.reset();
      })
      .catch((error) => {
        recaptchaRef.current?.reset();
        console.log("error=>",error)
        const msg = "Username or Password does not match, please try again";
        toast.error(msg);
        setDisableSubmitButton(false)
      });
  };

  const onSubmitWithReCAPTCHA = async () => {
    const token = await recaptchaRef.current.executeAsync();

  }

  return (
    <>
      <div className="flex min-h-screen flex-col justify-center py-16 sm:px-6 lg:px-8 bg-slate-50 bg-[url('https://volatilepips.com/edgefin-bg-new.jpg')] ">
        <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
          <div className="bg-white dark:bg-slate-800 py-8 px-4 shadow sm:rounded-lg sm:px-10 border border-[#dbff06]">
            <div className="text-center flex justify-center">
              <div className="py-1 px-6 rounded">
                <img
                  className="mx-auto h-28 w-auto"
                  src="logo.png"
                  alt="KORVATO"
                />
              </div>
            </div>
            <div className="p-4">
              <h2 className="text-center text-2xl font-medium tracking-tight text-gray-300">
                Welcome Back
              </h2>
              <p className="text-center text-sm text-gray-700">
                Please enter your details.
              </p>
            </div>

            <form className="space-y-6" onSubmit={(e) => handleSubmit(e)}>
              <div>
                <div className="mt-1">
                  <input
                    id="email"
                    name="email"
                    type="text"
                    autoComplete="off"
                    placeholder="Email"
                    required
                    className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-red-500 focus:outline-none focus:ring-red-500 sm:text-sm"
                  />
                </div>
              </div>

              <div>
                <div className="mt-1">
                  <input
                    id="password"
                    name="password"
                    type="password"
                    autocomplete="new-password"
                    placeholder="Password"
                    required
                    className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-red-500 focus:outline-none focus:ring-red-500 sm:text-sm"
                  />
                </div>
              </div>

              <ReCAPTCHA
                ref={recaptchaRef}
                sitekey="6Ldx1s0pAAAAAM5pi4e67LPAHHxb1RHfvzWSDXF0"
              />

              <div className="text-center">
                <button
                  type="submit"
                  className="bg-gradient-to-r from-[#dbff06] to-[#27ff10] flex w-full justify-center rounded-md py-2 px-4 text-sm font-medium text-white shadow-sm"
                  disabled={disableSubmitButton}
                >
                  {(disableSubmitButton ? <FaSpinner color="gold"  className="spinner"  /> : "Sign-In")}
                </button>
              </div>

            </form>
          </div>
        </div>
      </div>
    </>
  );
};
export default Login;
