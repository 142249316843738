/* eslint-disable */
import Api from "./Api";
// import AuthToken from "./AuthTokenServices";

export default {

  async getAllCustomer(payload) {
    // await AuthToken.getCurrentAuth();
    const response = await Api().get(`api/dashboard/customer?search=${payload}`);
    return response;
  },

  async saveCustomer(payload) {
    // await AuthToken.getCurrentAuth();
    const response = await Api().post(`api/dashboard/customer`, payload);
    return response;
  },

  async getAllSingleCustomer(payload) {
    // await AuthToken.getCurrentAuth();
    const response = await Api().get(`api/dashboard/customer/${payload}`);
    return response;
  },
  async setCustomerStatus(payload) {
    // await AuthToken.getCurrentAuth();
    const response = await Api().post(`api/dashboard/customer/status`, payload);
    return response;
  },
  async deleteCustomer(payload) {
    // await AuthToken.getCurrentAuth();
    const response = await Api().post(`api/dashboard/customer/remove`, payload);
    return response;
  },

  async importCustomer(payload) {
    // await AuthToken.getCurrentAuth();
    const response = await Api().post(`api/dashboard/customer/import`, payload);
    return response;
  },
  async getCustomerInfo(payload) {
    // await AuthToken.getCurrentAuth();
    const response = await Api().post(`api/dashboard/customer/get-customer-info`,payload);
    return response;
  },
  async resetPassword(payload) {
    // await AuthToken.getCurrentAuth();
    const response = await Api().post(`api/dashboard/customer/reset-password`,payload);
    return response;
  },

  ////
};
