/* eslint-disable */
import Api from "./Api";
// import AuthToken from "./AuthTokenServices";

export default {

  async getAllGroupAcc(payload) {
    // await AuthToken.getCurrentAuth();
    const response = await Api().get(`api/dashboard/group-acc?search=${payload}`);
    return response;
  },

  async saveGroupAcc(payload) {
    // await AuthToken.getCurrentAuth();
    const response = await Api().post(`api/dashboard/group-acc`, payload);
    return response;
  },

  async saveMultiGroupAcc(payload) {
    // await AuthToken.getCurrentAuth();
    const response = await Api().post(`api/dashboard/group-acc/save-multi`, payload);
    return response;
  },
  async updateGroupAccStatus(payload) {
    // await AuthToken.getCurrentAuth();
    const response = await Api().post(`api/dashboard/group-acc/update-status`, payload);
    return response;
  },

  async getSingleGroupAcc(payload) {
    // await AuthToken.getCurrentAuth();
    const response = await Api().get(`api/dashboard/group-acc/${payload}`);
    return response;
  },
  async setGroupAccStatus(payload) {
    // await AuthToken.getCurrentAuth();
    const response = await Api().post(`api/dashboard/group-acc/status`, payload);
    return response;
  },
  async deleteGroupAcc(payload) {
    // await AuthToken.getCurrentAuth();
    const response = await Api().post(`api/dashboard/group-acc/remove`, payload);
    return response;
  },

  /* Paramere Link Accounts */
  async savePCMultiGroupAcc(payload) {
    // await AuthToken.getCurrentAuth();
    const response = await Api().post(`api/dashboard/param-group-acc/save-multi`, payload);
    return response;
  },

  //deletePCGroupAcc
  async deletePCGroupAcc(payload) {
    // await AuthToken.getCurrentAuth();
    const response = await Api().post(`api/dashboard/param-group-acc/remove`, payload);
    return response;
  },

  async syncAutoOffAcc(payload) {
    const response = await Api().post(`api/dashboard/param-group-acc/sync`, payload);
    return response;
  },

  async getOffAccounts(payload) {
    const response = await Api().get(`api/dashboard/param-group-acc/auto/all/get-off-accounts`, payload);
    return response;
  },

  async singleSyncAutoOffAcc(payload) {
    const response = await Api().post(`api/dashboard/param-group-acc/single-acc-sync`, payload);
    return response;
  },

};
