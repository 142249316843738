import React, { useState } from "react";
import MasterServices from "../ApiServices/MasterServices";

const FileUploadWithPreview = ({ 
    onFileUpload, 
    bannerName, 
    maxFileSize = 5, 
    FolderName='global',
    multiple = false, 
    doc_id=0,
    allowedTypes = ["image/jpeg", "image/png", "image/gif", "application/pdf"] 
}) => {
    const [files, setFiles] = useState([]);
    const [filePreviews, setFilePreviews] = useState([]);
    const [uploading, setUploading] = useState(false);
    const [error, setError] = useState("");
    const [uploadProgress, setUploadProgress] = useState(0); // Track progress

    const validateFile = (file) => {
        // if (!allowedTypes.includes(file.type)) {
        //     return `Invalid file type. Allowed types are: ${allowedTypes.join(", ")}`;
        // }
        // if (file.size > maxFileSize * 1024 * 1024) {
        //     return `File size exceeds the limit of ${maxFileSize}MB`;
        // }

        if (file.size > maxFileSize * 1024 * 1024) {
            return `File size exceeds the limit of ${maxFileSize}MB`;
        }
        return null; // No error for file type, as all types are allowed

    };

    const handleFileUpload = async (event) => {
        const selectedFiles = event.target.files;
        if (!selectedFiles.length) return;

        const validFiles = [];
        const invalidFiles = [];
        Array.from(selectedFiles).forEach((file) => {
            const error = validateFile(file);
            if (error) {
                invalidFiles.push({ file, error });
            } else {
                validFiles.push(file);
            }
        });

        if (invalidFiles.length) {
            setError(`Errors in selected files: ${invalidFiles.map((item) => item.error).join(", ")}`);
            return;
        }

        setError("");

        const previews = validFiles.map((file) => URL.createObjectURL(file));

        if (multiple) {
            setFiles((prev) => [...prev, ...validFiles]);
            setFilePreviews((prev) => [...prev, ...previews]);
        } else {
            setFiles([validFiles[0]]);
            setFilePreviews([previews[0]]);
        }

        const formData = new FormData();
        validFiles.forEach((file) => {
            formData.append("doc_id", doc_id);
            formData.append("fileData", file);
            formData.append("fileName", file.name);
            formData.append("folderName", FolderName);
        });

        try {
            setUploading(true);
            setUploadProgress(0); 

            const { data, status } = await MasterServices.uploadDoc(formData, {
                onUploadProgress: (progressEvent) => {
                    const progress = Math.round((progressEvent.loaded / progressEvent.total) * 100);
                    setUploadProgress(progress); 
                },
            });

            if (status) {
                onFileUpload(data); 
                setFiles([]);
                setFilePreviews([]);
            } else {
                setError("Upload failed. Please try again.");
            }
        } catch (error) {
            setError("Error uploading files: " + error.message);
        } finally {
            setUploading(false);
            setUploadProgress(0); 
        }
    };

    const handleRemoveFile = (index) => {
        
        const updatedFiles = files.filter((_, i) => i !== index);
        const updatedPreviews = filePreviews.filter((_, i) => i !== index);

        setFiles(updatedFiles);
        setFilePreviews(updatedPreviews);
    };

    return (
        <div>
            <label
                htmlFor="prod_type"
                className="block text-sm font-medium text-gray-300 mb-1"
            >
                {bannerName || "Upload File(s)"}
                <span className="text-red-600">*</span>
            </label>

            <input
                className="block text-sm font-medium text-gray-300 mb-1"
                id="file-input"
                type="file"
                multiple={multiple}
                onChange={handleFileUpload}
                style={{ display: "block", margin: "10px 0" }}
                disabled={uploading}
            />
            {uploading && (
                <div style={{ marginBottom: "10px", width: "100%" }}>
                    <div
                        style={{
                            height: "10px",
                            width: "100%",
                            backgroundColor: "#e0e0e0",
                            borderRadius: "5px",
                            overflow: "hidden",
                        }}
                    >
                        <div
                            style={{
                                height: "100%",
                                width: `${uploadProgress}%`,
                                backgroundColor: "#4caf50",
                                transition: "width 0.3s",
                            }}
                        ></div>
                    </div>
                    <p style={{ marginTop: "5px", fontSize: "12px", color: "#4caf50" }}>
                        {uploadProgress}% Uploaded
                    </p>
                </div>
            )}

            {error && <div className="text-red-500 text-sm">{error}</div>}

            <div style={{ display: "flex", flexWrap: "wrap", gap: "10px" }}>
                {filePreviews.map((preview, index) => (
                    <div
                        key={index}
                        style={{ position: "relative", display: "inline-block", marginBottom: "10px" }}
                    >
                        <img
                            src={preview}
                            alt={`preview-${index}`}
                            style={{
                                height: "80px",
                                width: "80px",
                                objectFit: "cover",
                                borderRadius: "5px",
                            }}
                        />
                        {multiple && (
                            <button
                                onClick={() => handleRemoveFile(index)}
                                style={{
                                    position: "absolute",
                                    top: "-5px",
                                    right: "-5px",
                                    backgroundColor: "red",
                                    color: "white",
                                    border: "none",
                                    borderRadius: "50%",
                                    cursor: "pointer",
                                    width: "20px",
                                    height: "20px",
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                }}
                            >
                                &times;
                            </button>
                        )}
                    </div>
                ))}
            </div>
        </div>
    );
};

export default FileUploadWithPreview;
