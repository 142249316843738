import React, { useEffect, useState, Fragment, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import { pcGroupSchema } from "../../../../schemas";
import { FallingLinesLoader } from "../../../../components/spinners/Spinner";
import Breadcrumb from "../../../../components/Breadcrumb";
import ConfirmationModal from "../../../../components/ConfirmationModal";
import RemoveConfirmationModal from "../../../../components/DeleteConfirmationModal";
import DeleteAccConfirmationModal from "../../../../components/DeleteAccConfirmationModal";
import { FaSpinner } from "react-icons/fa";
import GroupServices from "../../../../ApiServices/GroupServices";
import GroupAccServices from "../../../../ApiServices/GroupAccServices";
import { toast } from "react-toast";
import { getAllowedActions, checkPermissionsAndRedirect } from "../../../../helper/commonHelper";
import FSLoader from "../../../../components/FSLoader";
import GroupCard from './PCGroupCard';
import emptyImg from "../../../../assets/images/empty_common.png"
import { Dialog, DialogPanel, DialogTitle, Transition, TransitionChild } from '@headlessui/react'
import { CheckIcon, XMarkIcon } from '@heroicons/react/24/outline'
import Select from "react-select";
import CopyAccountServices from "../../../../ApiServices/CopyAccountServices";
import LoadingButton from "../../../../components/LoadingButton";
import Editor from '@monaco-editor/react';
import { ListBulletIcon, TrashIcon } from "@heroicons/react/20/solid";


function PCGroupList() {
	const pages = [{ title: "Paramater Config", href: "/param-config", module_id: 12 }];
	const [modalOpenFlage, setmodalOpenFlage] = useState(false);
	const [groupList, setGroupList] = useState([]);
	const [isLoading, setIsLoading] = useState(true);
	const [isFSLoading, setSFLoading] = useState(false);
	const [isSaveBtnApiCall, setIsSaveBtnApiCall] = useState(false);
	const [loadingRemoveGrpIndex, setLoadingRemoveGrpIndex] = useState(-1);
	const [openAddGroup, setOpenAddGroup] = useState(false);
	const [openAddGroupAcc, setOpenAddGroupAcc] = useState(false);
	const [disableSubmitButton, setDisableSubmitButton] = useState(false);
	const [pgrp_id, setGroupid] = useState(0);
	const [gam_id, setGroupMapid] = useState(0);
	const [groupMapShowType, setGroupMapShowType] = useState(-1);
	const [addCurrentAccStatus, setAddCurrentAccStatus] = useState(-1);
	const [accountList, setAccountList] = useState([]);
	const [offAccs, setOffAccs] = useState([]);
	const [offAccsFilter, setOffAccsFilter] = useState([]);
	const [accountSelected, setAccountSelected] = useState(null);
	const [currentAddAccGroup, setCurrentAddAccGroup] = useState(null);
	const [senderVal, setSenderVal] = useState(null);
	const [receiverVal, setReceiverVal] = useState(null);
	const [riskType, setRiskType] = useState(null);
	const [multiplier, setMultiplier] = useState(null);
	const [configData, setConfigData] = useState("");
	const editorRef = useRef(null);
	const [open, setOpen] = useState(false);
	const [initValAddAcc, setInitValAddAcc] = useState({
		gam_risk_type: "NONE",
		gam_multiplier: 0,
		gam_acc_type: "",
		gam_ss: "",
		gam_rs: "",
	});

	const initialValues = {
		pgrp_id: "",
		pgrp_name: "",
		pgrp_start_balance: "",
		pgrp_end_balance: "",
		pgrp_config_risk: "Low_Risk",
		pgrp_is_active: ""
	};

	const [formGroup, setFormGroup] = useState(initialValues);

	const [confirmationModal, setConfirmationModal] = useState({
		status: false,
		pgrp_acc_status: 0,
		acc_no: "",
		gam_id: null,
		fk_pgrp_id: null,
	});
	const [removeConfirmationModal, setRemoveConfirmationModal] = useState({
		status: false,
		pgrp_id: null,
	});
	const [removeAccConfirmationModal, setRemoveAccConfirmationModal] = useState({
		status: false,
		pgam_id: null,
	});


	const OnChangeAccountType = (fk_pgrp_id, gam_is_smo, account, acc_no, accAr, pgrp_acc_status) => {

		let gam_id = account?.gam_id;
		const filterData = accAr?.filter((item) => item.gam_is_smo == 1)
		let title = "Are you sure you want to modify?";
		if (pgrp_acc_status == 1) {
			if (filterData.length > 0) {
				title = "Are you sure you want to Activate Master For Account No : " + (acc_no) + " One Account Already Have Master Function On " + (filterData.length > 0 ? filterData[0]?.m_account_relation?.acc_no : "");
			} else {
				let Status = getStatusTitle(pgrp_acc_status);
				title = "Are you sure you want to Activate " + Status + " For Account No : " + (acc_no);
			}
		} else {
			let Status = getStatusTitle(pgrp_acc_status);
			title = "Are you sure you want to Activate " + Status + " For Account No : " + (acc_no);

		}

		setConfirmationModal({ fk_pgrp_id, gam_id, acc_no, pgrp_acc_status, status: true, title });
	};

	<ConfirmationModal
		title={confirmationModal?.title ? confirmationModal?.title : "Are you sure you want to modify settings?"}
		confirmationButtonText="Yes"
		open={confirmationModal.status}
		onDelete={() => {
			onStatusUpdate(
				confirmationModal.fk_pgrp_id,
				confirmationModal.gam_id,
				confirmationModal.acc_status,
				confirmationModal.pgrp_acc_status,
				confirmationModal.acc_no
			);
		}
		}
		setOpen={setConfirmationModal}
	/>

	const onStatusUpdate = async (fk_pgrp_id, gam_id, acc_status, acc_no, pgrp_acc_status) => {
		let payload = {
			gam_id: gam_id,
			fk_pgrp_id: fk_pgrp_id,
			acc_no: acc_no,
			pgrp_acc_status: pgrp_acc_status,
			gam_is_smo: 1,
			StatusTitle: getStatusTitle(pgrp_acc_status)
		}
		let { data, status } = await GroupAccServices.updateGroupAccStatus(payload)
		if (status === 200) {
			if (data.status) {

				toast.success(data.message);
				getAllPCGroup(1);
			}
		} else {
			//toast.error(data.message);
		}
		setConfirmationModal({ fk_pgrp_id: null, gam_id: null, acc_no: "", status: false });
		getAllPCGroup(1);
	}

	// Update the filtered accounts when the search term changes
	const handleSearchChange = (e) => {
		const searchTerm = e.target.value;

		// If search term is empty, show all accounts
		if (!searchTerm) {
			setOffAccsFilter(offAccs);
		} else {
			const filteredAccounts = offAccs.filter((acc) =>
				String(acc.acc_no).includes(searchTerm)
			);
			setOffAccsFilter(filteredAccounts);
		}
	};

	const getStatusTitle = (pgrp_acc_status) => {
		if (pgrp_acc_status === 0) {
			return "Off";
		} else if (pgrp_acc_status === 1) {
			return "Master";
		} else if (pgrp_acc_status === 2) {
			return "Slave";
		} else {
			return "";
		}
	};

	const navigate = useNavigate();


	const [allowedActions, setAllowedActions] = useState([]);
	useEffect(() => {
		const permissions = JSON.parse(localStorage.getItem("user_permissions"));
		if (permissions && window.location.pathname) {
			const checkPermissionsAndRedirectFunc = checkPermissionsAndRedirect(window.location.pathname, permissions, navigate);
			const actions = getAllowedActions(permissions, window.location.pathname);
			setAllowedActions(actions);
		}
	}, []);

	useEffect(() => {
		document.title = "Korvato  • Parameter Config";
		getAllTradeAcc(1);
		getAllPCGroup(1);
		getOffAccounts();
	}, []);

	const getAllTradeAcc = async (payload) => {
		try {
			const res = await CopyAccountServices.getAllCopyTrade(payload)
			if (res.status) {
				setAccountList(res.data.data);
			}
		} catch (e) {
			console.log(e, "error in getAllModules");
			//toast.error("Fatal Error");
		}
	}

	const getAllPCGroup = async (payload) => {
		setIsLoading(true);
		try {
			const res = await GroupServices.getAllPCGroup(payload)
			if (res.status) {
				setGroupList(res.data.data);
			}
			setIsLoading(false);
		} catch (e) {
			console.log(e, "error in getAllModules")
			//toast.error("Fatal Error")
			setIsLoading(false);
		}
	}

	const onAddAccount = (data) => {
		console.log(data);
		setCurrentAddAccGroup(data);
		setOpenAddGroupAcc(true);
	}

	const handleAccStateChange = (data) => {
		setAddCurrentAccStatus(data)
	}

	const handleAccountChange = (data) => {
		setAccountSelected(data);
	}

	const handleSenderSuffixChange = (data) => {
		setSenderVal(data);
	}

	const handleReceiverSuffixChange = (data) => {
		setReceiverVal(data);
	}

	const handleRiskTypeChange = (data) => {
		setRiskType(data);
	}
	const handleMultiplierChange = (data) => {
		setMultiplier(data);
	}


	const { values, errors, touched, handleBlur, handleChange, handleSubmit } =
		useFormik({
			enableReinitialize: true,
			initialValues: formGroup,
			validationSchema: pcGroupSchema,
			onSubmit: async (values, action) => {

				setIsSaveBtnApiCall(true);

				let body = {
					pgrp_id: pgrp_id ? pgrp_id : 0,
					pgrp_is_active: true,
					pgrp_name: values.pgrp_name,
					pgrp_start_balance: values.pgrp_start_balance,
					pgrp_end_balance: values.pgrp_end_balance,
					pgrp_config_risk: values.pgrp_config_risk,
					pgrp_config: configData ? configData : "{}",
				};
				if (pgrp_id === undefined || pgrp_id === null || pgrp_id === "") {
					setTimeout(() => {
						setDisableSubmitButton(false)
					}, 1000)
					setDisableSubmitButton(true)
					let { data, status, message } = await GroupServices.savePCGroup(body)
				} else {

					setTimeout(() => {
						setDisableSubmitButton(false)
					}, 1000)
					setDisableSubmitButton(true)
					let { data, status, message } = await GroupServices.savePCGroup(body)
				}
				action.resetForm();
				getAllPCGroup(3);
				setOpenAddGroup(false);
				if (modalOpenFlage === true) {
					getAllPCGroup(3);
					setmodalOpenFlage(false);
					setDisableSubmitButton(false);
				}
				setIsSaveBtnApiCall(false);
			},
		});

	const addAccountFormik = useFormik({
		initialValues: initValAddAcc,
		validate: values => {
			const errors = {};
			return errors;
		},
		onSubmit: async values => {

			setIsSaveBtnApiCall(true);

			if (accountSelected) {

				const payload = {
					pgam_id: 0,
					accounts: currentAddAccGroup,
					pgam_data: accountSelected,
					fk_pgrp_id: groupMapShowType == 0 ? initValAddAcc.fk_pgrp_id : currentAddAccGroup ? parseInt(currentAddAccGroup?.pgrp_id) : 0,
					pgam_pgrp_name: groupMapShowType == 0 ? initValAddAcc.gam_pgrp_name : currentAddAccGroup ? currentAddAccGroup?.pgrp_name : "N/A",
					// pgam_pgrp_start_balance: groupMapShowType == 0 ? initValAddAcc.gam_pgrp_start_balance : currentAddAccGroup ? currentAddAccGroup?.pgrp_start_balance : 00,
					// pgam_pgrp_end_balance: groupMapShowType == 0 ? initValAddAcc.gam_pgrp_end_balance : currentAddAccGroup ? currentAddAccGroup?.pgrp_end_balance : 00

				}

				console.log({ payload });

				//gam_id, fk_pgrp_id, fk_acc_id, gam_is_smo, gam_risk_type, gam_multiplier
				const response = await GroupAccServices.savePCMultiGroupAcc(payload);

				if (response.data.status) {
					setOpenAddGroupAcc(false);
					toast.success(response.data.message)
				} else {
					setOpenAddGroupAcc(false);
					toast.error(response.data.message)
				}

				setIsSaveBtnApiCall(false);
			} else {
				setIsSaveBtnApiCall(false);
				alert("Please, Select Account No");
			}
			getAllPCGroup(1);
		},
	});

	const handleClickMenu = (data) => {
		// console.log(data);
		// setGroupMapid(data?.id?.gam_id);
		// return
		// setGroupMapShowType(data.type);
		if (data.type == 1) {
			//Delete
			handleRemoveGroupAcc(data.data);
		}
	}

	const syncAutoOffAcc = async () => {
		setSFLoading(true);
		const response = await GroupAccServices.syncAutoOffAcc();
		if (response.data.status) {
			toast.success(response.data.message)
		} else {
			toast.error(response.data.message)
		}
		setSFLoading(false);
		getAllPCGroup(1);
		getOffAccounts();
	}

	const handleSingleSyncAcc = async(item) => {
		console.log(`Syncing account: `,{acc_id:item.acc_id,acc_no:item.acc_no});
		setSFLoading(true);
		const response = await GroupAccServices.singleSyncAutoOffAcc({acc_id:item.acc_id,acc_no:item.acc_no});
		if (response.data.status) {
			toast.success(response.data.message)
		} else {
			toast.error(response.data.message)
		}
		setSFLoading(false);
		getOffAccounts();
		// Add your sync logic here
	};

	const getOffAccounts = async () => {
		const response = await GroupAccServices.getOffAccounts();
		if (response.data.status) {
			//toast.success(response.data.message)
			console.log("getOffAccounts => ", response.data.data);
			if (response.data.data.length > 0) {
				setOffAccs(response.data.data);
				setOffAccsFilter(response.data.data);
			}
		}
	}

	const handleEditGroup = (data) => {
		console.log(data);
		setFormGroup({
			pgrp_id: data?.pgrp_id,
			pgrp_name: data?.pgrp_name,
			pgrp_start_balance: data?.pgrp_start_balance,
			pgrp_end_balance: data?.pgrp_end_balance,
			pgrp_config_risk: data?.pgrp_config_risk,
			pgrp_is_active: data?.pgrp_is_active,
		});
		setGroupid(data?.pgrp_id);
		setOpenAddGroup(true);
		setConfigData(JSON.stringify(JSON.parse(data?.pgrp_config), null, 2));
	}

	const handleRemoveGroup = (data, index) => {
		setLoadingRemoveGrpIndex(index);
		let title = "Are you sure you want to Remove Group Data For Group Name " + data?.pgrp_name;
		setRemoveConfirmationModal({ pgrp_id: data?.pgrp_id, status: true, title });
	}

	const onDeleteGroup = async (pgrp_id, pgrp_status) => {
		let payload = {
			pgrp_id: pgrp_id,
			pgrp_status: !pgrp_status,
		}
		let { data, status } = await GroupServices.deletePCGroup(payload);
		// Simulate an async action

		if (status === 200) {
			if (data.status) {
				toast.success(data.message);
				getAllPCGroup(1);
			}
		} else {
			toast.error(data.message);
		}
		setRemoveConfirmationModal({ pgrp_id: null, status: false });
		getAllPCGroup(1);
		setLoadingRemoveGrpIndex(-1);
	}

	const handleRemoveGroupAcc = (data) => {
		console.log({ handleRemoveGroupAcc: data });
		let title = "Are you sure you want to Remove Group Acc No " + data?.pgam_acc_no;
		setRemoveAccConfirmationModal({ pgam_id: data?.pgam_id, status: true, title });
	}

	const onDeleteGroupAcc = async (pgam_id, pgam_status) => {
		let payload = {
			pgam_id: pgam_id,
			pgam_is_deleted: !pgam_status,
		}
		let { data, status } = await GroupAccServices.deletePCGroupAcc(payload);
		// Simulate an async action

		if (status === 200) {
			if (data.status) {
				toast.success(data.message);
			}
		}
		setRemoveAccConfirmationModal({ pgam_id: null, status: false });
		getAllPCGroup(1);
	}

	const handleEditorDidMount = (editor, monaco) => {
		editorRef.current = editor;
		//console.log("editor.getValue()=>", editor.getValue())
		editor.onDidChangeModelContent(() => {
			// Update configData state when the editor content changes
			setConfigData(editor.getValue());
		});
		/* if (editorRef.current.getValue() != null && editorRef.current.getValue() != "") {
		  // setConfigData(editorRef.current.getValue());
		  setConfigData(editorRef.current.getValue());
		  console.log(editorRef.current.getValue());
		} */
	}

	return (
		<div className="px-4 sm:px-4 lg:px-4">
			<FSLoader isLoading={isFSLoading} title="Processing..." />

			<Breadcrumb pages={pages} />
			<ConfirmationModal
				title={confirmationModal?.title ? confirmationModal?.title : "Are you sure you want to modify settings?"}
				confirmationButtonText="Yes"
				open={confirmationModal.status}
				onDelete={() => {
					onStatusUpdate(
						confirmationModal.fk_pgrp_id,
						confirmationModal.gam_id,
						confirmationModal.acc_status,
						confirmationModal.acc_no,
						confirmationModal.pgrp_acc_status,
					);
					console.log("confirmationModal.acc_no=>", confirmationModal);
				}
				}
				setOpen={setConfirmationModal}
			/>

			<RemoveConfirmationModal
				title={removeConfirmationModal?.title ? removeConfirmationModal?.title : "Are you sure you want to modify settings?"}
				confirmationButtonText="Yes"
				open={removeConfirmationModal.status}
				onDelete={() => {
					onDeleteGroup(
						removeConfirmationModal.pgrp_id,
						removeConfirmationModal.status,
					);
				}
				}
				setOpen={setRemoveConfirmationModal}
			/>

			<DeleteAccConfirmationModal
				title={removeAccConfirmationModal?.title ? removeAccConfirmationModal?.title : "Are you sure you want to modify settings?"}
				confirmationButtonText="Yes"
				open={removeAccConfirmationModal.status}
				onDelete={() => {
					onDeleteGroupAcc(
						removeAccConfirmationModal.pgam_id,
						removeAccConfirmationModal.status,
					);
				}
				}
				setOpen={setRemoveAccConfirmationModal}
			/>


			<div className="border-b border-gray-200 pb-5 sm:flex sm:items-center sm:justify-between">
				<h3 className="text-base font-semibold leading-6 text-gray-900">Param Config - Groups</h3>
				<div className="mt-3 sm:ml-4 sm:mt-0">

					<button
						onClick={() => setOpen(true)}
						type="button"
						className="mr-2 inline-flex items-center rounded-md bg-[#fff] px-3 py-2 text-sm font-semibold text-black shadow-md"
					>
						Show Off Accounts
					</button>

					<button
						onClick={() => {
							syncAutoOffAcc()
						}}
						type="button"
						className="mr-2 inline-flex items-center rounded-md bg-[#1bf4ff] px-3 py-2 text-sm font-semibold text-black shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
					>
						Sync Auto Off Acc
					</button>

					<button
						onClick={() => {
							setOpenAddGroup(true)
						}}
						type="button"
						className="inline-flex items-center rounded-md bg-[#0172fd] px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
					>
						+ Add New Config Group
					</button>
				</div>
			</div>

			<div className="mt-4 flex">

			</div>

			{isLoading ? (
				<FallingLinesLoader />
			) : (
				<div className="container mx-auto p-4">
					<div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-3 gap-4">
						{
							groupList.map((group, ind) => (
								<GroupCard index={ind} deleteIndex={loadingRemoveGrpIndex} key={group.pgrp_id} group={group} OnChangeAccountType={OnChangeAccountType} onAddAccount={onAddAccount} handleClickMenu={handleClickMenu} handleEditGroup={handleEditGroup}
									handleRemoveGroup={handleRemoveGroup}
								/>
							))
						}
					</div>
					{
						groupList.length <= 0 ? (
							<div className="border bg-white rounded-md p-4 my-4 w-full flex flex-col text-center items-center justify-between">

								<img src={emptyImg} alt="empty" width="30%" className="text-center items-center justify-between" />
								<p className="text-slate-500">Ops! No Active Goup</p>
								<button
									onClick={() => {
										setOpenAddGroup(true)
									}}
									type="button"
									className="mt-4 mb-4 rounded-sm bg-white px-4 py-2.5 text-sm font-semibold text-[#0172fd] shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
								>
									Let's Add New Group
								</button>

							</div>
						) : null
					}
				</div>
			)}


			{/* Modal for Acc Group */}
			<Transition show={openAddGroup}>
				<Dialog className="relative z-10" onClose={setOpenAddGroup}>
					<TransitionChild
						enter="ease-out duration-300"
						enterFrom="opacity-0"
						enterTo="opacity-100"
						leave="ease-in duration-200"
						leaveFrom="opacity-100"
						leaveTo="opacity-0"
					>
						<div className="fixed inset-0 bg-gray-500 bg-opacity-75 backdrop-blur-sm transition-opacity" />
					</TransitionChild>

					<div className="fixed inset-0 z-10 w-screen overflow-y-auto">
						<div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
							<TransitionChild
								enter="ease-out duration-300"
								enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
								enterTo="opacity-100 translate-y-0 sm:scale-100"
								leave="ease-in duration-200"
								leaveFrom="opacity-100 translate-y-0 sm:scale-100"
								leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
							>
								<DialogPanel className="relative transform overflow-hidden rounded-lg bg-gray-700 px-2 pb-2 pt-2 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-md">
									<div>
										<div className="mt-2 sm:mt-2">
											<DialogTitle as="h3" className="text-base text-center  text-white border-b border-[#999] font-semibold leading-6 text-gray-900 pb-3">
												{pgrp_id > 0 ? 'Update Group' : 'Add New PC Group'}
											</DialogTitle>
											<div className="mt-2">
												<form
													onSubmit={(e) => {
														e.preventDefault();
														console.log(e);
														handleSubmit(e);
													}}
													className="flex h-full flex-col"
												>
													<div className="h-0 flex-1 overflow-y-auto">
														<div className="flex flex-1 flex-col justify-between">
															<div className="p-4 sm:p-6">
																<div className="mt-2 mb-2 sm:col-span-2 sm:mt-0">
																	<div>
																		<label
																			htmlFor="pgrp_name"
																			className="block text-sm  text-white font-medium text-gray-900 mt-2 mb-1"
																		>
																			Group Name
																		</label>
																	</div>
																	<div className="mt-2 mb-3 sm:col-span-2 sm:mt-0">
																		<input
																			value={values.pgrp_name}
																			onChange={handleChange}
																			onBlur={handleBlur}
																			type="text"
																			placeholder="Enter Group Name"
																			name="pgrp_name"
																			autoComplete="off"
																			className="w-full rounded-md border-[1px] p-2 border-gray-600 bg-gray-600 text-gray-100 shadow-sm focus:border-cyan-500 sm:text-sm"
																		/>
																		{errors.pgrp_name &&
																			touched.pgrp_name ? (
																			<p className="text-red-600 text-sm">
																				{errors.pgrp_name}
																			</p>
																		) : null}
																	</div>
																</div>


																<div className="flex flex-wrap gap-6">

																	{/* Start Balance */}
																	<div className="w-full">
																		<label htmlFor="pgrp_start_balance" className="block text-sm text-white font-medium text-gray-900 mb-1">
																			Start Balance
																		</label>
																		<input
																			value={values.pgrp_start_balance}
																			onChange={handleChange}
																			onBlur={handleBlur}
																			type="number"
																			placeholder="Enter Start Balance"
																			name="pgrp_start_balance"
																			autoComplete="off"
																			className="w-full rounded-md border-[1px] p-2 border-gray-600 bg-gray-600 text-gray-100 shadow-sm focus:border-cyan-500 sm:text-sm"
																		/>
																		{errors.pgrp_start_balance && touched.pgrp_start_balance && (
																			<p className="text-red-600 text-sm">{errors.pgrp_start_balance}</p>
																		)}
																	</div>

																	{/* End Balance */}
																	<div className="w-full">
																		<label htmlFor="pgrp_end_balance" className="block text-sm text-white font-medium text-gray-900 mb-1">
																			End Balance
																		</label>
																		<input
																			value={values.pgrp_end_balance}
																			onChange={handleChange}
																			onBlur={handleBlur}
																			type="number"
																			placeholder="Enter End Balance"
																			name="pgrp_end_balance"
																			autoComplete="off"
																			className="w-full rounded-md border-[1px] p-2 border-gray-600 bg-gray-600 text-gray-100 shadow-sm focus:border-cyan-500 sm:text-sm"
																		/>
																		{errors.pgrp_end_balance && touched.pgrp_end_balance && (
																			<p className="text-red-600 text-sm">{errors.pgrp_end_balance}</p>
																		)}
																	</div>
																</div>

																<div className="mt-2 mb-2 sm:col-span-2 sm:mt-0">
																	<div>
																		<label
																			htmlFor="pgrp_config_risk"
																			className="block text-sm text-white font-medium text-gray-900 mt-2 mb-1"
																		>
																			Risk Type
																		</label>
																	</div>
																	<div className="mt-2 mb-3 sm:col-span-2 sm:mt-0">
																		<select
																			value={values.pgrp_config_risk}
																			defaultValue={values.pgrp_config_risk}
																			onChange={handleChange}
																			onBlur={handleBlur}
																			name="pgrp_config_risk"
																			className="w-full rounded-md border-[1px] p-2 border-gray-600 bg-gray-600 text-gray-100 shadow-sm focus:border-cyan-500 sm:text-sm"
																		>
																			<option disabled>Select Type</option>
																			<option value="Low_Risk">Low_Risk</option>
																			<option value="Medium_Risk">Medium_Risk</option>
																			<option value="High_Risk">High_Risk</option>
																		</select>
																		{errors.pgrp_config_risk && touched.pgrp_config_risk ? (
																			<p className="text-red-600 text-sm">
																				{errors.pgrp_config_risk}
																			</p>
																		) : null}
																	</div>
																</div>


																<div className="mt-2 mb-2 sm:col-span-2 sm:mt-0">
																	<div>
																		<label
																			className="block text-sm text-white font-medium text-gray-900 mt-2"
																		>
																			Config Data - (JSON/TEXT)
																		</label>
																	</div>
																	<div className="mb-3 sm:col-span-2 sm:mt-0">

																		<Editor
																			height="30vh"
																			theme="vs-dark"
																			defaultLanguage="json"
																			defaultValue={configData}
																			onMount={handleEditorDidMount}
																			className="w-full border bg-gray-800"
																		/>
																	</div>
																</div>

															</div>
														</div>
													</div>
													<div className="flex flex-shrink-0 justify-end px-4 pb-2">
														<button
															type="button"
															className="rounded-md border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50"
															onClick={() => {
																setFormGroup({
																	pgrp_id: 0,
																	pgrp_name: "",
																	pgrp_start_balance: "",
																	pgrp_end_balance: "",
																	pgrp_config_risk: "Low_Risk",
																	pgrp_is_active: true,
																});
																setGroupid(null);
																setConfigData("");
																setOpenAddGroup(false);
															}}
														>
															Cancel
														</button>
														{
															isSaveBtnApiCall ? (
																<LoadingButton title=" Saving" />
															) : (
																<button
																	type="submit"
																	className="ml-4 inline-flex justify-center rounded-md bbt bg-gradient-to-b bg-[#0172fd] py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-cyan-700"
																>
																	{pgrp_id > 0 ? 'Update' : 'Add'} Group
																</button>
															)
														}
													</div>
												</form>
											</div>
										</div>
									</div>
								</DialogPanel>
							</TransitionChild>
						</div>
					</div>
				</Dialog>
			</Transition>

			{/* Modal for Add Acc in Group */}
			<Transition show={openAddGroupAcc}>
				<Dialog className="relative z-10" onClose={setOpenAddGroupAcc}>
					<TransitionChild
						enter="ease-out duration-300"
						enterFrom="opacity-0"
						enterTo="opacity-100"
						leave="ease-in duration-200"
						leaveFrom="opacity-100"
						leaveTo="opacity-0"
					>
						<div className="fixed inset-0 bg-gray-500 bg-opacity-75 backdrop-blur-sm transition-opacity" />
					</TransitionChild>

					<div className="fixed inset-0 z-10 w-screen overflow-y-auto">
						<div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
							<TransitionChild
								enter="ease-out duration-300"
								enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
								enterTo="opacity-100 translate-y-0 sm:scale-100"
								leave="ease-in duration-200"
								leaveFrom="opacity-100 translate-y-0 sm:scale-100"
								leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
							>
								<DialogPanel className="relative transform overflow-hidden rounded-lg bg-white px-2 pb-2 pt-2 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-sm">
									<div>
										<div className="mt-2 sm:mt-2">
											<DialogTitle as="h3" className="text-base text-center border-b border-[#999] font-semibold leading-6 text-gray-900 pb-3">
												{groupMapShowType == 2 ? 'Show Account Details' : gam_id <= 0 ? 'Link account to group > ' + currentAddAccGroup?.pgrp_name : 'Update Account'}
											</DialogTitle>
											<div className="mt-2">
												<form
													onSubmit={addAccountFormik.handleSubmit}
													className="flex h-full flex-col"
												>
													<div className="h-0 flex-1 overflow-y-auto">
														<div className="flex flex-1 flex-col justify-between">
															<div className="p-4 sm:p-6">
																<div className="mt-2 mb-2 sm:col-span-2 sm:mt-0">
																	<div>
																		<label
																			htmlFor="pgrp_name"
																			className="block text-sm font-medium text-gray-900 mt-2 mb-1"
																		>
																			Choose Account
																		</label>
																	</div>
																	<div className="mt-2 mb-3 sm:col-span-2 sm:mt-0">
																		<Select
																			isMulti
																			name="Account"
																			defaultValue={accountSelected}
																			value={accountSelected}
																			onChange={(option) => {
																				handleAccountChange(option)
																			}}
																			options={offAccs}
																			classNamePrefix="select"
																			className="basic-multi-select"
																			menuPortalTarget={document.body}
																			styles={{
																				menuPortal: (base) => ({
																					...base,
																					zIndex: 9999,
																				}),
																			}}
																		/>
																	</div>
																</div>

															</div>
														</div>
													</div>
													<div className="flex flex-shrink-0 justify-end px-4 pb-2">
														<button
															type="button"
															className="rounded-md border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50"
															onClick={() => {
																setInitValAddAcc({
																	gam_risk_type: "NONE",
																	gam_multiplier: 0
																})
																setCurrentAddAccGroup(null);
																setAddCurrentAccStatus(-1);
																handleAccountChange(null);
																setOpenAddGroupAcc(false);
																setGroupMapShowType(-1);
																setGroupMapid(0);
															}}
														>
															Cancel
														</button>
														{groupMapShowType !== 2 ? (
															<>
																{
																	isSaveBtnApiCall ? (
																		<LoadingButton title=" Saving" />
																	) : (
																		<button
																			type="submit"
																			className="ml-4 inline-flex justify-center rounded-md bbt bg-gradient-to-b bg-[#0172fd] py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-cyan-700"
																		>
																			{gam_id > 0 ? 'Update' : 'Add'} Account
																		</button>
																	)
																}
															</>
														) : (<></>)}
													</div>
												</form>
											</div>
										</div>
									</div>
								</DialogPanel>
							</TransitionChild>
						</div>
					</div>
				</Dialog>
			</Transition>


			{/* Add Acc Config */}
			<Transition.Root show={open} as={Fragment}>
				<Dialog className="relative z-10" onClose={setOpen}>
					<div className="fixed inset-0" />

					<div className="fixed inset-0 overflow-hidden">
						<div className="absolute inset-0 overflow-hidden">
							<div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10 sm:pl-16">
								<Transition.Child
									as={Fragment}
									enter="transform transition ease-in-out duration-500 sm:duration-700"
									enterFrom="translate-x-full"
									enterTo="translate-x-0"
									leave="transform transition ease-in-out duration-500 sm:duration-700"
									leaveFrom="translate-x-0"
									leaveTo="translate-x-full"
								>
									<Dialog.Panel className="pointer-events-auto w-screen max-w-md">
										<div
											className="flex h-full flex-col divide-y divide-gray-200 bg-white shadow-xl">
											<div className="h-0 flex-1 overflow-y-auto">


												<div className="bg-indigo-700 px-4 py-6 sm:px-6">
													<div className="flex items-center justify-between">
														<Dialog.Title className="text-base font-semibold leading-6 text-white">
															Off Account List
														</Dialog.Title>
														<div className="ml-3 flex h-7 items-center">
															<button
																type="button"
																className="relative rounded-md bg-indigo-700 text-indigo-200 hover:text-white focus:outline-none focus:ring-2 focus:ring-white"
																onClick={() => setOpen(false)}
															>
																<span className="absolute -inset-2.5" />
																<span className="sr-only">Close panel</span>
																<XMarkIcon className="h-6 w-6" aria-hidden="true" />
															</button>
														</div>
													</div>
												</div>

												{/* // give me search box and list of off accounts that is off; use offAccs as array of off accounts */}
												<div className="p-4">
													<input
														type="text"
														placeholder="Search Off Accounts"
														className="block w-full max-w-lg rounded-md border-[1px] p-2 border-gray-300 shadow-sm focus:border-[1px] focus:border-cyan-500 sm:max-w-xs sm:text-sm"
														onChange={handleSearchChange}
													/>
													<ul className="mt-4">
														{offAccsFilter.map((acc) => (
															<li key={acc.acc_no} className="border-b py-2 flex items-center justify-between">
																<span>{acc.acc_no}</span>
																<button
																	onClick={() => handleSingleSyncAcc(acc)}
																	className="ml-4 px-3 py-1 text-white bg-cyan-500 hover:bg-cyan-600 rounded-md text-sm"
																>
																	Sync Acc
																</button>
															</li>
															// <li key={acc.acc_no} className="border-b py-2">
															// 	{acc.acc_no}
															// </li>
														))}
													</ul>
												</div>
											</div>

											<div className="flex flex-shrink-0 justify-end px-4 py-4">
												<button
													type="button"
													className="rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
													onClick={() => setOpen(false)}
												>
													Close
												</button>
											</div>
										</div>
									</Dialog.Panel>
								</Transition.Child>
							</div>
						</div>
					</div>
				</Dialog>
			</Transition.Root>

		</div>
	);
}
export default PCGroupList;
