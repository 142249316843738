/* eslint-disable */
import Api from "./Api";
// import AuthToken from "./AuthTokenServices";
export default {


  //  product-file Section Start
  async SaveFile(payload) {
    const response = await Api().post(`api/dashboard/product-file`, payload);
    return response;
  },

  async getAllFile(payload) {
    let isObj = payload !== null && typeof payload === "object" && !Array.isArray(payload);
    const params = new URLSearchParams(payload).toString();
    const response = await Api().get(isObj ? `api/dashboard/product-file/?${params}` : `api/dashboard/product-file/?search=${payload}`);
    return response;
  },
  async getAllSingleFile(payload) {
    const response = await Api().get(`api/dashboard/product-file/${payload}`);
    return response;
  },
  async setFileStatus(payload) {
    const response = await Api().post(`api/dashboard/product-file/status`, payload);
    return response;
  },
  async deleteFile(payload) {
    const response = await Api().post(`api/dashboard/product-file/remove`, payload);
    return response;
  },

  async importFile(payload) {
    const response = await Api().post(`api/dashboard/product-file/import`, payload);
    return response;
  },
  //  product-file Section End



  async getAllFileLicense(payload) {
    const response = await Api().get(`api/dashboard/product-file/plan/cust-plan/all?search=${payload}`);
    return response;
  },

  async saveFileLicense(payload) {
    const response = await Api().post(`api/dashboard/product-file/plan/cust-plan`, payload);
    return response;
  },

  async getAllSingleFileLicense(payload) {
    const response = await Api().get(`api/dashboard/product-file/plan/cust-plan/single/${payload}`);
    return response;
  },
  async setFileLicenseStatus(payload) {
    const response = await Api().post(`api/dashboard/product-file/plan/cust-plan/status`, payload);
    return response;
  },
  async deleteFileLicense(payload) {
    const response = await Api().post(`api/dashboard/product-file/plan/cust-plan/remove`, payload);
    return response;
  },

  async importFileLicense(payload) {
    const response = await Api().post(`api/dashboard/product-file/plan/cust-plan/import`, payload);
    return response;
  },
  async getFileLicenseInfo(payload) {
    const response = await Api().post(`api/dashboard/product-file/plan/cust-plan/get-product-file-info`, payload);
    return response;
  },

}