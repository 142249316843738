import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import ProtectedRoute from "./protectedRoute";
import Login from "../container/pages/login/Login";
import CustomerRegister from "../container/pages/login/CustomerRegister";
import CustomerLogin from "../container/pages/login/CustomerLogin";
import UsersList from "../container/pages/users/Users";
import RoleList from "../container/pages/role/RoleList";
import CountryList from "../container/pages/country/CountryList";
import Profile from "../container/pages/profile/Profile";
import GenderList from "../container/pages/gender/Gender";
import StateList from "../container/pages/state/State";
import CityList from "../container/pages/city/CityNew";
import EditPermissions from "../container/pages/role/EditPermissions";
import WhopUser from "../container/pages/whop/users/Users";
import Customers from "../container/pages/customers/index";
import WhopProduct from "../container/pages/whop/product/Product";
import Product from "../container/pages/whop/product/index";
// import WhopLicense from "../container/pages/whop/license/License";
import WhopLicense from "../container/pages/whop/license/index";
import WhopAddLicense from "../container/pages/whop/license/addLicense";
// import WhopAccount from "../container/pages/whop/account/Account";
import WhopAccount from "../container/pages/whop/account/index";
import WhopAddAccount from "../container/pages/whop/account/AddAccountNew";
import PlanList from "../container/pages/whop/plan/PlanList";
import MasterPlanList from "../container/pages/whop/plan/index";
import CopyTrade from "../container/pages/whop/copyTrade/CopyTrade";
import GroupList from "../container/pages/whop/group/GroupList";
import GroupAccList from "../container/pages/whop/group//GroupAccList";
import AddGroupAccount from "../container/pages/whop/group/AddGroupAccount";
import ParamConfigList from "../container/pages/whop/param_config/ParamConfigList";
import PCGroupAccList from "../container/pages/whop/param_config/PCGroupAccListNew";
import ChatTopic from "../container/pages/forum/index";
import Chat from "../container/pages/forum/chat";
import Google2FA from "../container/pages/google/2FA";
import ServerDetails from "../container/pages/google/serverDetails";
import Course from "../container/pages/course/index";
import CourseChapter from "../container/pages/course/chapter";

import Error from "../container/error/Error";
import Home from "../container/pages/home/Home";
import AccMetrix from "../container/pages/account_metrix/AccMetrix";
import AILog from "../container/pages/ai_log/AILog";

const RoutesConfig = () => {
  return (
    <Router>
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route path="/signUp" element={<Login />} />
        <Route path="/cust-signUp" element={<CustomerRegister />} />
        <Route path="/cust-login" element={<CustomerLogin />} />
        <Route path="*" element={<Error />} />
        <Route element={<ProtectedRoute />}>
          <Route path="/" element={<Home />} />
          <Route path="/product" element={<Product />} />
          <Route path="/google" element={<Google2FA />} />
          <Route path="/server-info" element={<ServerDetails />} />
          <Route path="/topic" element={<ChatTopic />} />
          <Route path="/chat" element={<Chat />} />
          <Route path="/chat/">
            <Route index element={<Chat />} />{" "}
            <Route path=":sct_id" element={<Chat />} />{" "}
          </Route>{" "}
          <Route path="/course" element={<Course />} />
          <Route path="/chapter" element={<CourseChapter />} />
          <Route path="/chapter/">
            <Route index element={<CourseChapter />} />{" "}
            <Route path=":course_id" element={<CourseChapter />} />{" "}
          </Route>{" "}
          <Route path="/user" element={<UsersList />} />

          <Route path="/plan" element={<MasterPlanList />} />
          <Route path="/plan/">
            <Route index element={<MasterPlanList />} />{" "}
            <Route path=":prod_id" element={<MasterPlanList />} />{" "}
          </Route>{" "}
          <Route path="/whop-plan" element={<PlanList />} />
          <Route path="/customer" element={<Customers />} />
          <Route path="/whop-product" element={<WhopProduct />} />

          <Route path="/license" element={<WhopLicense />} />
          <Route path="/license">
            <Route index element={<WhopLicense />} />
            <Route path=":wp_lic_id" element={<WhopAddLicense />} />
          </Route>
          <Route path="/account" element={<WhopAccount />} />
          <Route path="/account">
            <Route index element={<WhopAccount />} />
            <Route path=":fk_wp_lic_id" element={<WhopAddAccount />} />
          </Route>
          <Route path="/copy-trade" element={<CopyTrade />} />
          <Route path="/profile" element={<Profile />} />
          <Route path="/user-list" element={<UsersList />} />

          <Route path="/role" element={<RoleList />} />
          <Route path="/role">
            <Route index element={<RoleList />} />
            <Route path=":role_id" element={<EditPermissions />} />
          </Route>
          <Route path="/gender" element={<GenderList />} />
          <Route path="/country" element={<CountryList />} />
          <Route path="/state" element={<StateList />} />
          <Route path="/city" element={<CityList />} />
          <Route path="/accm" element={<AccMetrix />} />
          {/* <Route path="/group" element={<GroupList />} /> */}
          <Route path="/group-acc" element={<GroupAccList />} />
          <Route path="/group">
            <Route index element={<GroupAccList />} />
            <Route path=":fk_grp_id" element={<AddGroupAccount />} />
          </Route>
          {/* <Route path="/subscription/">
            <Route index element={<Subscription />} />
            <Route path=":customer_id" element={<Subscription />} />
          </Route> */}
          <Route path="/ai-log" element={<AILog />} />
          {/* <Route path="/param-config" element={<ParamConfigList />} /> */}
          <Route path="/param-config" element={<PCGroupAccList />} />
        </Route>
      </Routes>
    </Router>
  );

};

export default RoutesConfig;